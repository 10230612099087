import Dropdown from "react-bootstrap/Dropdown";
import { getIcon } from "../../utils";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import "./Header.scss";

export default function Header() {
  const history = useHistory();

  const handleSettings = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    history.push("/user/settings");
  };

  const handleLogout = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    sessionStorage.removeItem("authToken");
    localStorage.removeItem("authToken");
    history.push("/");
  };

  return (
    <header className="header-component">
      <Link to="/app">
        <h1>Game-Plan</h1>
      </Link>
      <div className="button-container">
        <Dropdown>
          <Dropdown.Toggle>{getIcon("user")}</Dropdown.Toggle>

          <Dropdown.Menu variant="dark">
            <Dropdown.Item onClick={handleSettings}>Settings</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </header>
  );
}
