import { Link } from "react-router-dom";
import { Draggable } from "react-smooth-dnd";
import { getTypeIcon, getPriorityIcon, getIcon } from "../../../../../utils";
import Dropdown from 'react-bootstrap/Dropdown'
import "./KanbanCard.scss";
import { useDispatch } from "react-redux";
import { handleRemoveCardFromBoard } from "../../../../../store/app";

export default function KanbanCard({ workspaceId, projectId, card }) {
  const { ticketKey, summary, cost, assignee, priority, type, taskCategory, id } =
    card;
  const dispatch = useDispatch();

  const handleRemoveClick = e =>{
    dispatch(handleRemoveCardFromBoard(id));
  }

  return (
    <Draggable className="kanban-card">
      <div className="header">
        <h3>
          <Link
            to={`/app/${workspaceId}/project/${projectId}/ticket/${ticketKey}`}
          >
            <span className="key">#{ticketKey}</span>
            <span>{summary}</span>
          </Link>
        </h3>
        <div className="kanban-card-corner">
          <div className="tag">{cost}</div>
          <Dropdown>
          <Dropdown.Toggle>{getIcon("dots")}</Dropdown.Toggle>

          <Dropdown.Menu variant="dark">
            <Dropdown.Item onClick={handleRemoveClick}>Remove Card</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        </div>
      </div>
      <hr />
      <div className="kanban-card-footer">
        <div>
          {getTypeIcon(type, taskCategory)} {getPriorityIcon(priority)}
        </div>
        {assignee && assignee.username}
      </div>
    </Draggable>
  );
}
