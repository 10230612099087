import { Card, Input } from "../../../";
import { ModalCardHeader } from "..";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createBoard } from "../../../../store/app";

export default function CreateBoardCard() {
  const { register, handleSubmit } = useForm({
    shouldUseNativeValidation: true,
  });
  const dispatch = useDispatch();

  const getHeader = (title) => {
    return <ModalCardHeader title={title} showClose />;
  };

  const onSubmit = (fields) => {
    dispatch(createBoard(fields));
  };

  return (
    <Card header={getHeader("Create Board")}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="cb-boardName">Board Name</label>
        <input
          className="modal-input"
          type="text"
          id="cb-boardName"
          {...register("displayName")}
          required
        />
        <button type="submit">Create</button>
      </form>
    </Card>
  );
}
