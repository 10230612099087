import { useSelector } from 'react-redux';
import { CreateTicketCard, CreateBoardCard, CreateGddPageCard, CreateProjectCard } from './components';
import './Modal.scss';

export default function Modal(){

  //=======================================================
  // State / Hooks
  //=======================================================

  // TODO: Add 'data' back to destructure when we need it
  const {visible, type} = useSelector(state => state.app.modal);

  //=======================================================
  // Handlers / Component Renders
  //=======================================================

  const getModalContents = () =>{
    switch(type){
      case 'CREATE_BOARD':
        return <CreateBoardCard />;
      case 'CREATE_TICKET':
        return <CreateTicketCard />;
      case 'CREATE_GDD_PAGE':
        return <CreateGddPageCard />;
      case 'CREATE_PROJECT':
        return <CreateProjectCard />;
      default:
        return (<></>);
    }
  }

  //=======================================================
  // Render
  //=======================================================

  return (
    <div className={`fullscreen-modal${!visible ? ' hidden' : ''}`}>
      <div className={`contents ${visible ? 'fullscreen-anim' : 'fullscreen-anim-end'}`}>
        {getModalContents()}
      </div>
    </div>
  )
}