import './Footer.scss';

export default function footer(){
  return(
    <footer className="footer">
      <ul className="links">
        <li>
          <a href="https://nicera.in/#home-about" target="_blank" rel="noopener noreferrer">About</a>
        </li>
        <li>
          <a href="https://rainylabs.com/legal/terms-and-conditions.html" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
        </li>
        <li>
          <a href="https://rainylabs.com/legal/privacy-policy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
        </li>
        <li>
          <a href="https://nicera.in/#home-contact" target="_blank" rel="noopener noreferrer">Contact</a>
        </li>
      </ul>
      <p className="copyright">&copy; Copyright 2019-2021 <a href="https://nicera.in/" target="_blank" rel="noopener noreferrer">Rainy Labs</a>, LLC.</p>
    </footer>
  )
}