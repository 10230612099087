import './MilestoneListView.scss';
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { getAppInformation } from "../../../store/app";


export default function MilestoneListView(){

  const {workspaceId, projectId} = useParams();
  const dispatch = useDispatch();
  
  useEffect(()=>{
    dispatch(getAppInformation({workspace: workspaceId, project: projectId}));
  }, [workspaceId, projectId, dispatch]);

  return(
    <div className="milestone-list-view">
      <h2>Milestone List Placeholder</h2>
      <p>List of all milstones</p>
      <p>Should show name + deadline + board count?</p>
    </div>
  );
}