import { useState } from "react";
import { useDispatch } from "react-redux";
import { Card, Input, MarkdownEditor } from "../../../../../components";
import { editTicket } from "../../../../../store/app";
import {
  getCostType,
  getTypeOptions,
  getStatusOptions,
  getPriorityOptions,
} from "../../../../../utils";

export default function EditDetailsCard({ ticket, project, handleEndEditing }) {
  const [fieldValues, setFieldValues] = useState({ ...ticket });

  // Destructure our ticket
  const { tags, parent } = ticket;

  // Destructure the cost type from project
  const { costType } = project;
  const dispatch = useDispatch();

  const updateFieldValue = (key, value) => {
    const values = { ...fieldValues };
    values[key] = value;
    setFieldValues(values);
  };

  // Returns types for select dropdown
  const getTypes = () => {
    return getTypeOptions().map((type, index) => {
      return (
        <option key={`${type.label}_${index}`} value={type.value}>
          {type.label}
        </option>
      );
    });
  };

  // Returns status for select dropdown
  const getStatuses = () => {
    return getStatusOptions().map((type, index) => {
      return (
        <option key={`${type.label}_${index}`} value={type.value}>
          {type.label}
        </option>
      );
    });
  };

  // Returns status for select dropdown
  const getPriorities = () => {
    return getPriorityOptions().map((type, index) => {
      return (
        <option key={`${type.label}_${index}`} value={type.value}>
          {type.label}
        </option>
      );
    });
  };

  const cardHeader = () => {
    return (
      <div className="card-header">
        <h2>
          <input
            value={fieldValues.summary}
            onChange={(e) => updateFieldValue("summary", e.target.value)}
          />
        </h2>
        <div className="header-buttons">
          <button onClick={handleCancel} className="primary">
            Cancel
          </button>
          <button onClick={handleSave} className="primary">
            Save
          </button>
        </div>
      </div>
    );
  };

  const handleCancel = (e) => {
    e.preventDefault();
    handleEndEditing();
  };

  const handleSave = (e) => {
    e.preventDefault();
    handleEndEditing();
    console.log("saving form fields");
    dispatch(editTicket(ticket.id, fieldValues, ticket.ticketKey));
  };

  return (
    <form onSubmit={handleSave}>
      <Card header={cardHeader()} onSubmit={handleSave}>
        <h3>Details</h3>
        <div className="details-container">
          <div className="details-row">
            <div>
              <label htmlFor="dc-type">Type:</label>
              <select
                value={fieldValues.type}
                onChange={(e) => updateFieldValue("type", e.target.value)}
                name="type"
                id="dc-type"
                required
              >
                {getTypes()}
              </select>
            </div>
            <div>
              <label htmlFor="dc-status">Status:</label>
              <select
                value={fieldValues.status}
                onChange={(e) => updateFieldValue("status", e.target.value)}
                name="status"
                id="dc-status"
                required
              >
                {getStatuses()}
              </select>
            </div>
          </div>
          <div className="details-row">
            <div>
              <label htmlFor="dc-cost">
                Estimate ({getCostType(costType)}):
              </label>
              <input
                id="dc-cost"
                type="number"
                min="0"
                name="cost"
                value={fieldValues.cost}
                onChange={(e) => updateFieldValue("cost", e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="dc-priority">Priority:</label>
              <select
                value={fieldValues.priority}
                onChange={(e) => updateFieldValue("priority", e.target.value)}
                name="priority"
                id="dc-priority"
                required
              >
                {getPriorities()}
              </select>
            </div>
          </div>
          <div className="details-row">
            <div>
              <dt>Parent:</dt>
              <dd>{parent || "None"}</dd>
            </div>
          </div>
          <div className="details-row">
            <dt>Tags:</dt>
            <dd>{tags}</dd>
          </div>
        </div>
        <h3>Acceptance Criteria</h3>
        <div className="details-container">
          Checkboxes to toggle individual A/C items here
        </div>
        <section>
          <h3>Description</h3>
          {console.log("fieldvalues", fieldValues)}
          <div className="details-container">
            <label htmlFor="dc-description"></label>
            <MarkdownEditor
              id="dc-description"
              content={fieldValues.description}
              onChange={(e) => updateFieldValue("description", e.target.value)}
              name="description"
            />
          </div>
        </section>
      </Card>
    </form>
  );
}
