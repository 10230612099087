import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import { getAppInformation, setModalState } from "../../../store/app";

/**
 * View at /app/:workspaceId (no project selected).
 * Handles workspace invites + new project modal (if we have no projects)
 * @returns Dashboard with no selected project
 */
export default function DashboardView() {
  //=======================================================
  // State / Hooks
  //=======================================================

  const { workspaceId } = useParams();
  const dispatch = useDispatch();

  const showNewProjectModal = () => {
    dispatch(setModalState({ visible: true, type: "CREATE_PROJECT" }));
  };

  //=======================================================
  // Effects
  //=======================================================

  // Fetch our workspace
  useEffect(() => {
    dispatch(getAppInformation({ workspace: workspaceId }));
  }, [dispatch, workspaceId]);

  //=======================================================
  // Render
  //=======================================================

  return (
    <div className="dashboard-view">
      <h2>Dashboard View</h2>

      <div>
        Activity Feed - We could pull recent activity from the 'recent projects'
        list. This would allow the user to see activity in those projects at a
        glance.
      </div>
      <div>
        Assigned Tickets - We could have a list of 10 or so assigned tickets
        (with pagination at the bottom).
      </div>
      <div>
        Workspaces - List of workspaces, selecting one could give a list of
        projects to pick from (or just have a paginated list of workspaces)
      </div>
      <div>
        Deadlines - Place for milestones (possibly a graph for % chance of
        completion)
      </div>
      <div>
        Calendar - Calendar View for Milestones/Deadlines instead of deadlines?
      </div>

      <p>
        This will eventually be replaced with a modal popup if we have no
        projects
      </p>
      <button className="primary" onClick={showNewProjectModal}>
        New Project
      </button>
    </div>
  );
}
