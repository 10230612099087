import { Card } from "../../../";
import { ModalCardHeader } from "..";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getPriorityOptions,
  getTaskCategoryOptions,
  getTypeOptions,
} from "../../../../utils";
import { createTicket } from "../../../../store/app";

export default function CreateTicketCard() {
  const { project } = useSelector((state) => state.app);
  const { register, handleSubmit } = useForm({
    shouldUseNativeValidation: true,
    defaultValues: {
      project: project.id,
      type: "FEATURE",
      taskCategory: "GENERAL",
      priority: 1,
    },
  });
  const dispatch = useDispatch();

  const getHeader = (title) => {
    return <ModalCardHeader title={title} showClose />;
  };

  const onSubmit = (fields) => {
    console.log(fields);

    //dispatch here
    dispatch(createTicket(fields));
  };

  const getTypes = () => {
    return getTypeOptions().map((type, index) => {
      return (
        <option key={`${type.label}_${index}`} value={type.value}>
          {type.label}
        </option>
      );
    });
  };

  const getTaskCategories = () => {
    return getTaskCategoryOptions().map((category, index) => {
      return (
        <option key={`${category.label}_${index}`} value={category.value}>
          {category.label}
        </option>
      );
    });
  };

  const getPriorities = () => {
    return getPriorityOptions().map((priority, index) => {
      return (
        <option key={`${priority.label}_${index}`} value={priority.value}>
          {priority.label}
        </option>
      );
    });
  };

  return (
    <Card header={getHeader("Create Ticket")}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Core Fields */}
        <fieldset>
          <label htmlFor="ct-summary">Summary</label>
          <input
            className="modal-input"
            type="text"
            id="ct-summary"
            {...register("summary")}
            required
          />

          <label htmlFor="ct-type">Issue Type</label>
          <select name="type" id="ct-type" {...register("type")} required>
            {getTypes()}
          </select>

          <label htmlFor="ct-type">
            Task Type [TODO: Hide if issue type not 'task']
          </label>
          <select
            name="type"
            id="ct-type"
            {...register("taskCategory")}
            required
          >
            {getTaskCategories()}
          </select>
        </fieldset>

        {/* Auxilliary Fields */}
        <button>Advanced:</button>
        <fieldset>
          <label htmlFor="ct-description">Description</label>
          <textarea id="ct-description" {...register("description")} />

          <label htmlFor="ct-priority">Priority</label>
          <select
            className="modal-input"
            id="ct-priority"
            name="priority"
            {...register("priority")}
          >
            {getPriorities()}
          </select>

          {/* <label htmlFor="ct-assignee">Assign To</label>
          <select className="modal-input" id="ct-assignee" name="assignee" {...register("assignee")}>
          </select> */}
        </fieldset>

        <button type="submit">Create</button>
      </form>
    </Card>
  );
}
