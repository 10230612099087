import { useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import "./MarkdownEditor.scss";

export default function MarkdownEditor({ content, onChange, name, id }) {
  const [preview, setPreview] = useState(false);

  const handlePreviewClicked = (e) => {
    e.preventDefault();
    setPreview(!preview);
  };

  //=======================================================
  // Render
  //=======================================================

  return (
    <div className="markdown-editor" name={name} id={id}>
      <div className="preview-button">
        <button className="link-button" onClick={handlePreviewClicked}>
          {preview ? "edit" : "preview"}
        </button>
      </div>

      {/* Conditional Render for our Preview */}
      {preview ? (
        <div className="preview">
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
        </div>
      ) : (
        <div className="editor">
          <textarea value={content} onChange={onChange} cols="30" rows="10" />
        </div>
      )}
    </div>
  );
}
