import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { getAppInformation, setModalState } from "../../../store/app";

import { Card } from "../../../components";
import { IconWarning } from "../../../assets/icons";
import "./TicketListView.scss";
import {
  getPriorityIcon,
  getStatusOptions,
  getTypeIcon,
  getTypeOptions,
} from "../../../utils";

export default function TicketListView() {
  const { tickets } = useSelector((state) => state.app);
  const [typeFilter, setTypeFilter] = useState("ALL");
  const [statusFilter, setStatusFilter] = useState("ALL");
  const [nameFilter, setNameFilter] = useState("");
  const [showClosed, setShowClosed] = useState(false);
  const { workspaceId, projectId } = useParams();
  const dispatch = useDispatch();

  const getTypes = () => {
    return getTypeOptions().map((type, index) => {
      return (
        <option key={`${type.label}_${index}`} value={type.value}>
          {type.label}
        </option>
      );
    });
  };

  const updateTypeFilter = (e) => {
    setTypeFilter(e.target.value);
  };

  const updateShowClosed = (e) => {
    e.preventDefault();
    setShowClosed((current) => !current);
  };

  const updateNameFilter = (e) => setNameFilter(e.target.value);

  const resetFilter = (e) => {
    e.preventDefault();
    setStatusFilter("ALL");
    setTypeFilter("ALL");
    setNameFilter("");
    setShowClosed(false);
  };

  const getStatuses = () => {
    return getStatusOptions().map((status, index) => {
      return (
        <option key={`${status.label}_${index}`} value={status.value}>
          {status.label}
        </option>
      );
    });
  };

  const updateStatusFilter = (e) => {
    setStatusFilter(e.target.value);
  };

  //=======================================================
  // Component Renders / Handlers
  //=======================================================

  // Header for our card list (contains filters and stuff)
  const cardHeader = () => {
    return (
      <div className="card-header">
        <div className="filter-container">
          <span>Filter Name:</span>
          <input type="text" onChange={updateNameFilter} value={nameFilter} />

          <div>
            <span>Status:</span>
            <select
              name="type"
              id="ct-type"
              onChange={updateStatusFilter}
              value={statusFilter}
            >
              <option value="ALL">All</option>
              {getStatuses()}
            </select>
          </div>

          <div>
            <span>Type:</span>
            <select
              name="type"
              id="ct-type"
              onChange={updateTypeFilter}
              value={typeFilter}
            >
              <option value="ALL">All</option>
              {getTypes()}
            </select>
          </div>

          <button className="primary" onClick={updateShowClosed}>
            {showClosed ? "Hide Closed" : "Show Closed"}
          </button>

          <button className="primary" onClick={resetFilter}>
            Reset
          </button>
        </div>

        <button onClick={handleCreateTicket} className="primary">
          Create Ticket
        </button>
      </div>
    );
  };

  // Render view to return our ticket list
  const getTickets = () => {
    if (!tickets.length) return;

    console.log({ tickets });

    // Filter our tickets

    const filteredTickets = tickets.filter((ticket) => {
      const nameFilterCheck = nameFilter
        ? ticket.summary.toLowerCase().includes(nameFilter.toLowerCase())
        : true;

      if (!showClosed && ticket.status === "CLOSED") return false;
      if (!showClosed && ticket.status === "COMPLETED") return false;

      if (
        (ticket.type === typeFilter || typeFilter === "ALL") &&
        (ticket.status === statusFilter || statusFilter === "ALL") &&
        nameFilterCheck
      )
        return true;
    });

    return filteredTickets.map((ticket, index) => {
      const closed =
        ticket.status === "CLOSED" || ticket.status === "COMPLETED";

      return (
        <li key={`${ticket.id}_${index}`}>
          {closed && <hr className="strike-through" />}
          <Link
            to={`/app/${workspaceId}/project/${projectId}/ticket/${ticket.ticketKey}`}
            className="ticket-link"
          >
            <i className="type">
              {getTypeIcon(ticket.type, ticket.taskCategory)}
            </i>
            <i className="priority">{getPriorityIcon(ticket.priority)}</i>
            <div className="summary">
              <span className="key">#{ticket.ticketKey}</span>
              {ticket.summary}
            </div>
            {ticket.assignee && (
              <div className="assignee">{ticket.assignee.username}</div>
            )}
            {ticket.board && (
              <div className="board">{ticket.board.displayName}</div>
            )}
            <div className="status">{ticket.status}</div>
          </Link>
        </li>
      );
    });
  };

  // Open our create ticket modal
  const handleCreateTicket = () => {
    dispatch(setModalState({ visible: true, type: "CREATE_TICKET" }));
  };

  //=======================================================
  // Effects
  //=======================================================

  // Fetch our workspace when we load settings
  useEffect(() => {
    dispatch(
      getAppInformation({
        workspace: workspaceId,
        project: projectId,
        tickets: true,
      })
    );
  }, [workspaceId, projectId, dispatch]);

  //=======================================================
  // Render
  //=======================================================

  return (
    <div className="ticket-list-view">
      <h2>Tickets</h2>
      {tickets.length ? (
        // Ticket List
        <Card className="no-header-bg no-content-pad" header={cardHeader()}>
          <ul className="card-list tickets">{getTickets()}</ul>
        </Card>
      ) : (
        // No Tickets
        <Card>
          <div className="no-tickets">
            <i>
              <IconWarning />
            </i>
            <p>
              This project contains no tickets. To get started,{" "}
              <button className="link-button" onClick={handleCreateTicket}>
                Create one
              </button>
              .
            </p>
          </div>
        </Card>
      )}
      {/* <div className="pagination">{`< 1 2 3 >`}</div> */}
    </div>
  );
}
