import './GddView.scss';

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppInformation, setModalState } from "../../../store/app";
import { useParams } from "react-router";
import { Card, DocumentModel } from '../../../components';

export default function GddView(){


  //=======================================================
  // State / Hooks
  //=======================================================


  const dispatch = useDispatch();
  const {workspaceId, projectId} = useParams();
  const {gdd} = useSelector(state => state.app);

  //=======================================================
  // Component Renders
  //=======================================================

  // Render our credit <ul>
  const getCredits = () =>{
    
    // Fallback for no credits
    if(!gdd?.credits?.length){
      return (
        <p>'No Additional Credits'</p>
      )
    }

    // Return an array of <li> for each credit
    const getCreditList = gdd.credits.map((credit, index) => 
      <li key={`${credit}_${index}`}>{credit}</li>
    )

    // Return our entire list
    return (
      <ul>
        {getCreditList()}
      </ul>
    )
  }

  const handleAddPageClicked = e =>{
    e.preventDefault();

    dispatch(setModalState({visible: true, type: 'CREATE_GDD_PAGE'}));
  }

  // Render for our document model header
  const getDocumentModelHeader = () =>{
    return(
      <div className="header-container">
        <h2>Document Model</h2>
        <button className="link-button" onClick={handleAddPageClicked}>+ Add Page</button>
      </div>
    )
  }

  //=======================================================
  // Effects
  //=======================================================

  // Fetch our workspace when we load settings
  useEffect(()=>{
    dispatch(getAppInformation({workspace: workspaceId, project: projectId, gdd: true}));
  }, [workspaceId, projectId, dispatch]);


  //=======================================================
  // Render
  //=======================================================

  return(
    <div className="gdd-view">
      <div className="left-column">

        <Card className="description" header={getDocumentModelHeader()}> 
          <DocumentModel/>
        </Card>
      </div>
      <div className="right-column">

      <Card className="description" header={<h3>Elevator Pitch</h3>}>
        <p>{gdd.elevatorPitch || 'Edit the document to add an elevator pitch.'}</p>
      </Card>

      <Card className="description" header={<h3>Summary</h3>}>
        <p>{gdd.summary || 'Edit the document to add a project summary.'}</p>
      </Card>


      <Card className="description" header={<h3>Additional Credits</h3>}>
        {getCredits()}
      </Card>
      </div>
    </div>
  )
}