import { useDispatch } from "react-redux";
import { setModalState } from "../../../../store/app";
import { IconClose } from "../../../../assets/icons";
import './ModalCardHeader.scss';

export default function ModalCardHeader({title, showClose}){

  const dispatch = useDispatch();

  // Reset our modal state on close
  const handleClose = e =>{
    e.preventDefault();
    dispatch(setModalState({visible: false, type: ''}));
  }

  return(
    <div className="modal-card-header">
      <h2>{title}</h2>
      <button onClick={handleClose} className="link-button close"><i><IconClose/></i></button>
    </div>
  )
}