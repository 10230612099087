import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAppInformation, updateGddPage } from "../../../store/app";
import { useParams } from "react-router";
import { useSelector } from "react-redux";

import { Card } from "../../../components";
import { Link } from "react-router-dom";
import { MarkdownEditor, DocumentModel } from "../../../components";
import { IconEdit } from "../../../assets/icons";
import remarkGfm from "remark-gfm";
import Breadcrumb from "react-bootstrap/Breadcrumb";

import "./GddPageView.scss";
import ReactMarkdown from "react-markdown";

export default function GddPageView() {
  const dispatch = useDispatch();
  const { workspaceId, projectId, pageId } = useParams();
  const { gdd, gddPage, project } = useSelector((state) => state.app);

  // State for our edit page
  const [editPage, setEditPage] = useState(false);
  const [titleValue, setTitleValue] = useState("");
  const [contentValue, setContentValue] = useState("");

  //=======================================================
  // Event Handlers
  //=======================================================

  // Returns the count of children to condtionally render section
  const getChildCount = () => {
    if (!gdd?.pages) return;

    const foundPage = gdd.pages.find((page) => page.urlId === pageId);

    return foundPage.children.length;
  };

  const handleEditPage = (e) => {
    e.preventDefault();
    setEditPage(true);
  };

  const handleEditCancel = (e) => {
    e.preventDefault();
    setEditPage(false);
  };

  const handleTitleChange = (e) => {
    setTitleValue(e.target.value);
  };

  const handleContentChange = (e) => {
    setContentValue(e.target.value);
  };

  const handleEditSave = (e) => {
    e.preventDefault();
    dispatch(updateGddPage(gddPage.id, titleValue, contentValue));
  };

  //=======================================================
  // Component Renders
  //=======================================================

  // Header for summary
  const getSummaryHeader = () => {
    if (editPage) {
      return (
        <input type="text" value={titleValue} onChange={handleTitleChange} />
      );
    }

    return (
      <div className="header-row">
        <h2>{gddPage.title}</h2>
        <button className="link-button" onClick={handleEditPage}>
          <i>
            <IconEdit />
          </i>
        </button>
      </div>
    );
  };

  // Returns our summary
  const getSummaryContent = () => {
    // Fallback
    if (!gddPage) return;

    //Editing
    if (editPage) {
      return (
        <>
          <MarkdownEditor
            content={contentValue}
            onChange={handleContentChange}
          />
          <div className="button-container">
            <button onClick={handleEditCancel} className="primary">
              Cancel
            </button>
            <button onClick={handleEditSave} className="primary">
              Save
            </button>
          </div>
        </>
      );
    }

    // Default Render
    return (
      <section>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>
          {gddPage.summary || "This page has no content. Edit it to add some!"}
        </ReactMarkdown>
      </section>
    );
  };

  // Generate our children list
  const generateChildren = () => {
    if (!gdd?.pages) return;

    const foundPage = gdd.pages.find((page) => page.urlId === pageId);

    const childList = foundPage.children.map((childIndex, index) => {
      const { title, urlId } = gdd.pages[childIndex];

      return (
        <li key={`${urlId}_${index}`}>
          <Link
            to={`/app/${workspaceId}/project/${projectId}/gdd/page/${urlId}`}
          >
            {title}
          </Link>
        </li>
      );
    });

    return <ul>{childList}</ul>;
  };

  // Build our top navigation
  const buildNav = () => {
    // Fallback with no gdd page loaded
    if (
      !Object.keys(gddPage).length ||
      !Object.keys(project).length ||
      !Object.keys(gdd).length
    )
      return;

    // Base url used for <Link>
    const baseUrl = `/app/${workspaceId}/project/${projectId}/gdd/`;

    // Current page in our gdd pages array
    const currentPage = gdd.pages.find((page) => page.urlId === pageId);
    let parent = currentPage.parent;
    const links = [];

    while (parent !== 0) {
      // Get our page
      const parentPage = gdd.pages[parent];

      // Generate link to parent page
      links.push(
        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{ to: `${baseUrl}page/${parentPage.urlId}` }}
        >
          {parentPage.title}
        </Breadcrumb.Item>
      );

      // Assign our parent
      parent = parentPage.parent;
    }

    // Return our entire navigation
    return (
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: baseUrl }}>
          {project.displayName} GDD
        </Breadcrumb.Item>
        {links.reverse()}
        <Breadcrumb.Item active>{`${gddPage.title}`}</Breadcrumb.Item>
      </Breadcrumb>
    );
  };

  //=======================================================
  // Effects
  //=======================================================

  useEffect(() => {
    if (!Object.keys(gddPage).length) return;
    setTitleValue(gddPage.title);
    setContentValue(gddPage.summary);

    // Set editing to false (since this fires after we update the page)
    setEditPage(false);
  }, [gddPage]);

  useEffect(() => {
    dispatch(
      getAppInformation({
        workspace: workspaceId,
        project: projectId,
        gdd: true,
        gddPage: pageId,
      })
    );
  }, [workspaceId, projectId, pageId, dispatch]);

  //=======================================================
  // Render
  //=======================================================

  if (!Object.keys(gddPage).length) {
    return <>Loading</>;
  }

  return (
    <div className="gdd-page-view">
      <p className="nav">{buildNav()}</p>
      <div className="gdd-container">
        <div className="left-column">
          {/* First Card */}
          <Card header={getSummaryHeader()}>{getSummaryContent()}</Card>

          <Card header={<h3>Attachments</h3>}>No Attachments</Card>

          {getChildCount() ? (
            <Card header={<h3>Children</h3>}>{generateChildren()}</Card>
          ) : (
            <></>
          )}

          <Card header={<h3>Comments</h3>}>Coming Soon!</Card>
        </div>

        <div className="right-column">
          <Card header={<h3>Document Model</h3>}>
            <DocumentModel canDrag={false} compact={true} />
          </Card>
        </div>
      </div>
    </div>
  );
}
