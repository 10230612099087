import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAppInformation } from "../../../store/app";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { ViewDetailsCard, EditDetailsCard } from "./components";

import "./TicketView.scss";
import { Card } from "../../../components";

export default function TicketView() {
  //=======================================================
  // State / Hooks
  //=======================================================

  const dispatch = useDispatch();
  const { workspaceId, projectId, ticketId } = useParams();
  const { ticket, project } = useSelector((state) => state.app);
  const [editing, setEditing] = useState(false);

  //=======================================================
  // Component Renders / Handlers
  //=======================================================

  const handleEditDetails = (e) => {
    e.preventDefault();
    setEditing(true);
  };

  const handleEndEditing = (e) => {
    setEditing(false);
  };

  //=======================================================
  // Effects
  //=======================================================

  // Fetch our workspace when we load settings
  useEffect(() => {
    dispatch(
      getAppInformation({
        workspace: workspaceId,
        project: projectId,
        ticket: ticketId,
      })
    );
  }, [workspaceId, projectId, ticketId, dispatch]);

  //=======================================================
  // Render
  //=======================================================

  return (
    <div className="ticket-view">
      <div className="left-column">
        {editing ? (
          <EditDetailsCard
            ticket={ticket}
            project={project}
            handleEndEditing={handleEndEditing}
          />
        ) : (
          <ViewDetailsCard
            ticket={ticket}
            project={project}
            handleEditDetails={handleEditDetails}
          />
        )}
        <Card header={<h3>Attachments</h3>}>
          Thumbnails of all attachments (including uploaded images)
        </Card>
        <Card header={<h3>Comments</h3>}>Comments / Work Log / Activity</Card>
      </div>
      <div className="right-column">
        <Card header={<h3>Information</h3>}>
          <ul>
            <li>Sprint Board:</li>
            <li>Milestone (set by sprint board)</li>
            <li>assigned to (assignee):</li>
            <li>date Created:</li>
            <li>Date Modified:</li>
            <li>Progress bar of estimated time vs logged task time</li>
            <li>
              Tasks (Loop through dependencies and separate all category task
              ones
            </li>
            <li>
              Dependencies (loop through all dependencies and pick ones that
              aren't tasks
            </li>
          </ul>
        </Card>
      </div>
    </div>
  );
}
