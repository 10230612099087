import { Card, Input } from "../../../";
import { ModalCardHeader } from "..";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getTaskCategoryOptions } from "../../../../utils";
import { createGddPage } from "../../../../store/app";
// import { createBoard } from "../../../../store/app";

export default function CreateBoardCard() {
  const { register, handleSubmit } = useForm({
    shouldUseNativeValidation: true,
    defaultValues: { category: "GENERAL" },
  });
  const dispatch = useDispatch();
  const { gdd } = useSelector((state) => state.app);

  const getHeader = (title) => {
    return <ModalCardHeader title={title} showClose />;
  };

  const getParentPages = () => {
    if (!Object.keys(gdd).length) return;

    const filteredPages = gdd.pages.filter((page) => page !== null);

    return filteredPages.map((page, index) => {
      return (
        <option key={`${page.urlId}_${index}`} value={index}>
          {page.title}
        </option>
      );
    });
  };

  const getGddCategories = () => {
    const options = getTaskCategoryOptions();

    return options.map((category, index) => (
      <option key={`${category.value}_${index}`} value={category.value}>
        {category.label}
      </option>
    ));
  };

  const onSubmit = (fields) => {
    dispatch(createGddPage(fields));
  };

  return (
    <Card header={getHeader("Create New Page")}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="newgdd-pageTitle">Title</label>
        <input
          className="modal-input"
          type="text"
          id="newgdd-pageTitle"
          {...register("title")}
          required
        />
        <label htmlFor="newgdd-summary">Content</label>
        <textarea id="newgdd-summary" {...register("summary")} />
        <label htmlFor="newgdd-parent">Parent Page</label>
        <select name="newgdd-parent" id="newgdd-parent" {...register("parent")}>
          {getParentPages()}
        </select>

        <label htmlFor="newgdd-category">Category</label>
        <select
          name="newgdd-category"
          id="newgdd-category"
          {...register("category")}
        >
          {getGddCategories()}
        </select>

        <button type="submit">Create</button>
      </form>
    </Card>
  );
}
