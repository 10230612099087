import './Card.scss';

export default function Card({children, header, className}){
  return(
    <div className={`card ${className ? className : ''}`}>
      {header && <div className="header">
        {header}
      </div>}
      <div className="content">
        {children}
      </div>
    </div>
  )
}