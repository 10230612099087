import { Card, Input } from "../../..";
import { ModalCardHeader } from "..";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createProject } from "../../../../store/app";

import "./CreateProjectCard.scss";

export default function CreateProjectCard() {
  const { register, handleSubmit } = useForm({
    shouldUseNativeValidation: true,
  });
  const dispatch = useDispatch();

  const onSubmit = (fields) => {
    dispatch(createProject(fields));
  };

  const getHeader = (title) => {
    return <ModalCardHeader title={title} showClose />;
  };

  //=======================================================
  // Render
  //=======================================================

  return (
    <Card header={getHeader("Create New Project")}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="newProject-displayName">Project Name</label>
        <input
          id="newProject-displayName"
          type="text"
          {...register("displayName")}
          required
        />

        <label htmlFor="newProject-description">Description</label>
        <textarea
          id="newProject-description"
          {...register("description")}
          required
        />

        <button>Create</button>
      </form>
    </Card>
  );
}
