import SortableTree, {
  getVisibleNodeCount,
} from "@nosferatu500/react-sortable-tree";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link, NavLink } from "react-router-dom";

// CSS and overrides for sortable tree
import "@nosferatu500/react-sortable-tree/style.css";
import "./DocumentModel.scss";
import { reparentGddPage } from "../../store/app";
import { useDispatch } from "react-redux";

export default function DocumentModel({ canDrag = true, compact }) {
  const { gdd } = useSelector((state) => state.app);
  const { workspaceId, projectId } = useParams();
  const [treeData, setTreeData] = useState([]);
  const dispatch = useDispatch();

  //=======================================================
  // Component Renders
  //=======================================================

  // Gets our tree data
  useEffect(() => {
    if (!Object.keys(gdd).length) return [];

    console.log("generating tree view");

    // Array of pages within our GDD
    const { pages } = gdd;

    // Root page that we will start building the tree from
    const rootPage = pages[0];

    // Recursive function to return all mapped children in index order
    const mapChildItemsFromFlatData = (childPages) => {
      return childPages.map((pageIndex) => {
        // Protection for reference to our parent
        if (!pageIndex) return undefined;

        const childPage = pages[pageIndex];
        let filledChildren = [];

        if (childPage?.children?.length) {
          filledChildren = mapChildItemsFromFlatData(childPage.children);
        }

        // TODO: Update expanded to only be true if the index is root
        return {
          ...childPage,
          children: filledChildren,
          expanded: true,
          index: pageIndex,
        };
      });
    };

    // Filter to clear all undefined indices (could combine the two functions into a reduce if performance is bad)
    const data = mapChildItemsFromFlatData(rootPage.children).filter(
      (page) => page !== undefined
    );

    // Map our root page to a new array
    setTreeData(data);
  }, [gdd]);

  //Tree View Dynamic height style
  const fetchNodeCount = () => {
    if (treeData) {
      return getVisibleNodeCount({ treeData });
    }

    return 0;
  };

  // Handler for whenever the tree updates at all (such as expanding or closing nodes)
  const handleTreeChange = (data) => {
    setTreeData(data);
  };

  // Handler for when a node is dragged and reparented
  const handleMoveNode = (args) => {
    //Get the parent index that we are moving under
    const parentIndex =
      args.nextParentNode && args.nextParentNode.index
        ? args.nextParentNode.index
        : 0;

    //Use position 0 as a fallback in case we insert into something with no children
    let position = 0;

    //Fetch index that we are inserting into
    if (
      args.nextParentNode &&
      args.nextParentNode.children &&
      args.nextParentNode.children.length
    ) {
      for (let i = 0; i < args.nextParentNode.children.length; i++) {
        let child = args.nextParentNode.children[i];

        if (args.node.pageId === child.pageId) {
          position = i;
          break;
        }
      }
    } else {
      position = args.treeIndex;
    }

    // Dispatch here
    dispatch(
      reparentGddPage(args.node.index, position, parentIndex, args.node.pageId)
    );
  };

  // Map our title in our tree nodes to a Link element
  const getNodeProps = ({ node, path }) => ({
    title: (
      <Link
        to={`/app/${workspaceId}/project/${projectId}/gdd/page/${node.urlId}`}
      >
        {node.title}
      </Link>
    ),
  });

  // Returns the compact tree on the individual page view
  const buildCompactTree = () => {
    // Fallback
    if (!Object.keys(gdd).length) return;

    // Helper function to generate children
    const generateChildItems = (childPages) => {
      const childList = [];
      const pages = gdd.pages;

      const baseUrl = `/app/${workspaceId}/project/${projectId}/gdd/page/`;

      for (let i = 0; i < childPages.length; i++) {
        let child = childPages[i];
        if (
          pages[child] &&
          pages[child].children &&
          pages[child].children.length
        ) {
          childList.push(
            <li key={pages[child] + i}>
              <NavLink
                activeClassName="active"
                exact={true}
                to={`${baseUrl}${pages[child] && pages[child].urlId}`}
              >
                {pages[child] && pages[child].title}
              </NavLink>
              <ul>{generateChildItems(pages[child].children)}</ul>
            </li>
          );
        } else {
          childList.push(
            <li key={pages[child] + i}>
              <NavLink
                activeClassName="active"
                exact={true}
                to={`${baseUrl}${pages[child] && pages[child].urlId}`}
              >
                {pages[child] && pages[child].title}
              </NavLink>
            </li>
          );
        }
      }
      return childList;
    };

    // Return our entire list
    return <ul>{generateChildItems(gdd.pages[0].children)}</ul>;
  };

  //=======================================================
  // Render
  //=======================================================

  if (compact) {
    return <div className="document-model compact">{buildCompactTree()}</div>;
  }

  return (
    <div className="document-model" style={{ height: fetchNodeCount() * 62 }}>
      <SortableTree
        treeData={treeData}
        onChange={handleTreeChange}
        generateNodeProps={getNodeProps}
        onMoveNode={handleMoveNode}
        canDrag={canDrag}
      />
    </div>
  );
}
