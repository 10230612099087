import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppInformation, setModalState } from "../../../store/app";
import { useParams } from "react-router";
import { Card } from "../../../components";
import { IconMenu, IconWarning } from "../../../assets/icons";

import "./BoardListView.scss";
import { Link } from "react-router-dom";

export default function BoardListView() {
  //=======================================================
  // Hooks / State
  //=======================================================

  const dispatch = useDispatch();
  const { workspaceId, projectId } = useParams();
  const { boards } = useSelector((state) => state.app);

  //=======================================================
  // Component Renders / Handlers
  //=======================================================

  const cardHeader = () => {
    return (
      <div className="card-header">
        <button onClick={handleCreateBoard} className="primary">
          New Board
        </button>
      </div>
    );
  };

  const sortAlphabetical = (unsortedBoards) =>{
    const arrayForSorting = [...unsortedBoards]

    return arrayForSorting.sort((a,b) =>{
      let nameA = a.displayName.toUpperCase(); // ignore upper and lowercase
      let nameB = b.displayName.toUpperCase(); // ignore upper and lowercase
      if (nameA.includes('ARCHIVE')){
        return 1;
      }
      if (nameB.includes('ARCHIVE')){
        return -1;
      }
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    })

  }

  const boardListItems = useMemo(() => {
    if (!boards.length) return;
    const sortedBoards = sortAlphabetical(boards);
    console.log(sortedBoards);

    return sortedBoards.map((board, index) => {
      return (
        <li key={`${board.name}_${index}`}>
          <Link
            to={`/app/${workspaceId}/project/${projectId}/board/${board.urlId}`}
            className={`board-link ${board.displayName.toLowerCase().includes('archive') ? 'archived' : ''}`}
          >
            <div className="board-name">{board.displayName}</div>
            {board.milestone && (
              <div className="milestone">INSERT_MILESTONE_NAME_HERE</div>
            )}
          </Link>
          <button className="link-button dots">
            <IconMenu />
          </button>
        </li>
      );
    });
  }, [boards, workspaceId, projectId]);

  // Called when we click the 'Create One' button when we have no boards
  const handleCreateBoard = (e) => {
    e.preventDefault();

    dispatch(setModalState({ visible: true, type: "CREATE_BOARD" }));
  };

  //=======================================================
  // Effects
  //=======================================================

  // Fetch our workspace when we load settings
  useEffect(() => {
    dispatch(
      getAppInformation({
        workspace: workspaceId,
        project: projectId,
        boards: true,
      })
    );
  }, [workspaceId, projectId, dispatch]);

  //=======================================================
  // Render
  //=======================================================

  return (
    <div className="board-list-view">
      <h2>Boards</h2>
      {boards.length ? (
        // Card where we have boards
        <Card className="no-header-bg no-content-pad" header={cardHeader()}>
          <ul className="card-list boards">{boardListItems}</ul>
        </Card>
      ) : (
        // Card with no boards found
        <Card>
          <div className="no-boards">
            <i>
              <IconWarning />
            </i>
            <p>
              This project contains no boards. To get started,{" "}
              <button className="link-button" onClick={handleCreateBoard}>
                Create one
              </button>
              .
            </p>
          </div>
        </Card>
      )}
      {/* <div className="pagination">{`< 1 2 3 >`}</div> */}
    </div>
  );
}
