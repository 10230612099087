import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";

import store from "./store/store.ts";

import "./index.scss";
import App from "./App";
//import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// Web Vital Reporting. Learn more: https://bit.ly/CRA-vitals
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // Console log our vitals
  //reportWebVitals(console.log);
} else {
  // production code
  // TODO: Install analytics for web vitals and GA
}
