import { Container } from "react-smooth-dnd";
import { KanbanCard } from "..";
import { useParams } from "react-router";
import "./Lane.scss";
import { useEffect, useState } from "react";

export default function Lane({ data, handleDropped }) {
  //=======================================================
  // State
  //=======================================================

  const { workspaceId, projectId } = useParams();
  const [cards, setCards] = useState([]);

  //=======================================================
  // Render Helpers
  //=======================================================

  // Fetch all cards
  const getCards = () => {
    return cards.map((card, index) => {
      return (
        <KanbanCard
          key={`${card.ticketKey}_${index}`}
          card={card}
          projectId={projectId}
          workspaceId={workspaceId}
        />
      );
    });
  };

  // Return our droppped item when we drop it
  const getPayload = (i) => {
    return { ...data.cards[i], fromLaneId: data.id };
  };

  //=======================================================
  // Handlers
  //=======================================================

  // Moves the card between lanes locally, then makes an API call to update it
  const handleCardDropped = (e) => {
    // Handle our shit locally
    const { addedIndex, removedIndex, payload } = e;

    // Fallback for our null indices
    if (addedIndex === removedIndex) return;

    // Locally push card into lane (prevents flicker)
    if (addedIndex !== null) {
      setCards([...cards, payload]);
    }

    // Locally remove card from lane (prevents flicker)
    if (removedIndex !== null) {
      let updatedCards = [...cards];
      updatedCards.splice(removedIndex, 1);
      setCards(updatedCards);
    }

    // Dispatch our API call (will override local changes)
    handleDropped(data.id, e);
  };

  //=======================================================
  // Effects
  //=======================================================

  // Effect to create a local copy of our cards in order to handle seamless dropping
  useEffect(() => {
    if (!Object.keys(data).length) return;

    // Set our cards to local state so we can mutate them
    setCards(data.cards);
  }, [data]);

  //=======================================================
  // Render
  //=======================================================

  return (
    <section className="lane" style={{ border: `1px solid ${data.color}` }}>
      <h3 style={{ color: data.color }}>{data.title}</h3>

      <Container
        groupName="1"
        onDrop={handleCardDropped}
        getChildPayload={getPayload}
      >
        {getCards()}
      </Container>
    </section>
  );
}
