import "./App.scss";

import { useEffect } from "react";
import { Switch, Redirect, Route } from "react-router";

import { Modal, PrivateRoute } from "./components";
import {
  AppView,
  LoginView,
  NotFoundView,
  RegisterView,
  UserSettingsView,
} from "./views";

export default function App() {
  //=======================================================
  // State
  //=======================================================

  //=======================================================
  // Effects
  //=======================================================

  // Effect to print commit hash in production
  useEffect(() => {
    const style = "color: dodgerblue; font-size:14px; ";
    console.log(
      `%c-> Build Version: ${
        process.env.REACT_APP_GIT_VERSION
          ? process.env.REACT_APP_GIT_VERSION
          : "dev"
      } <-`,
      style
    );
    console.log("key: 8ksd$6dS3J@FAds9");
  }, []);

  //=======================================================
  // Render
  //=======================================================

  return (
    <div className="App">
      <Switch>
        {/* Redirect our root to our login */}
        <Redirect exact from="/" to="/login" />

        <PrivateRoute path="/app">
          <AppView />
        </PrivateRoute>

        <Route exact path="/login">
          <LoginView />
        </Route>

        <Route exact path="/register">
          <RegisterView />
        </Route>

        <Route exact path="/user/settings">
          <UserSettingsView />
        </Route>

        {/* Fallback for 404 */}
        <Route>
          <NotFoundView />
        </Route>
      </Switch>

      <Modal />
    </div>
  );
}
