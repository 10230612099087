import './MilestoneView.scss';
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { getAppInformation } from "../../../store/app";


export default function MilestoneView(){

  const {workspaceId, projectId} = useParams();
  const dispatch = useDispatch();
  
  useEffect(()=>{
    dispatch(getAppInformation({workspace: workspaceId, project: projectId}));
  }, [workspaceId, projectId, dispatch]);

  return(
    <div className="milestone-view">
      <h2>Milestone Placeholder</h2>
      <p>Ability to add a new board to the milestone</p>
      <p>Show progress of each board in terms of hours outstanding/completed (or tickets outstanding/completed)</p>
      <p>Overall milestone velocity (week/sprint/month)</p>
      <p>Abilty to add deadline</p>
      <p>Deadline risk due to velocity</p>
      <p>User List?</p>
    </div>
  )
}