import { createSlice } from "@reduxjs/toolkit";
import { getAuthToken } from "../utils";
import axios from "axios";

const initialState = {
  formState: {
    login: {
      submitting: false,
      error: "",
    },
    "reset-password": {
      submitting: false,
      error: "",
    },
  },
  appLoading: false,
  modal: {
    visible: false,
    data: {},
    type: "",
  },

  // List Pages
  boards: [],
  tickets: [],

  // Individual Pages
  board: {},
  gdd: {},
  gddPage: {},
  project: {},
  ticket: {},
  user: {},
  workspace: {},
};

// Slice
const slice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    resetFormState: (state) => void (state.formState = initialState.formState),
    resetBoards: (state) => void (state.boards = []),
    resetModalState: (state) => void (state.modal = initialState.modal),
    setAppData: (state, action) => {
      state.user = { ...state.user, ...action.payload.user };
      state.workspace = { ...state.workspace, ...action.payload.workspace };
      state.project = { ...state.project, ...action.payload.project };
    },
    setBoard: (state, action) => {
      state.board = action.payload;
    },
    setBoards: (state, action) => {
      state.boards = action.payload;
    },
    setGdd: (state, action) => {
      state.gdd = action.payload;
    },
    setGddPage: (state, action) => {
      state.gddPage = action.payload;
    },
    setModalState: (state, action) => {
      state.modal = { ...state.modal, ...action.payload };
    },
    setProject: (state, action) => {
      state.project = { ...state.project, ...action.payload };

      // Clear our lists as well (since the project changed)
      state.boards = [];
      state.tickets = [];
    },
    setTicket: (state, action) => {
      state.ticket = action.payload;
    },
    setTickets: (state, action) => {
      state.tickets = action.payload;
    },
    updateWorkspace: (state, action) => {
      state.workspace = { ...state.workspace, ...action.payload };
    },
    updateFormState: (state, action) => {
      const { formKey, newState } = action.payload;
      const updatedForms = { ...state.formState };
      updatedForms[formKey] = { ...updatedForms[formKey], ...newState };
      state.formState = updatedForms;
    },
    updateProjectSettingsSuccess: (state, action) => {
      const projectList = [...state.projects];
      projectList[state.currentProject] = { ...action.payload };
      state.projects = projectList;
    },
    updateUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
  },
});

export default slice.reducer;

// Actions
export const {
  resetFormState,
  resetBoards,
  resetModalState,
  setAppData,
  setBoard,
  setBoards,
  setGdd,
  setGddPage,
  setModalState,
  setProject,
  setTicket,
  setTickets,
  updateFormState,
  updateUser,
  updateWorkspace,
} = slice.actions;

//=======================================================
// Async Thunks
//=======================================================

// Fetch user + default workspace information
// TODO: Finish error handling / writing user + workspaces
export const getUser = () => async (dispatch) => {
  try {
    const authToken = getAuthToken();

    const res = await fetch(`${process.env.REACT_APP_BASE_API_URL}/users/me`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    // Error handling
    if (res.status !== 200) {
      throw new Error("Error fetching user information");
    }

    const { user } = await res.json();

    // Update our user
    dispatch(updateUser(user));
  } catch (e) {
    return console.error("Get User Error: ", e.message);
  }
};

// Login
export const login =
  ({ username, password, rememberMe }) =>
  async (dispatch) => {
    try {
      // Set our login state
      dispatch(
        updateFormState({
          formKey: "login",
          newState: { submitting: true, error: "" },
        })
      );

      // Setup our request
      const requestBody = {
        username,
        password,
      };

      // Attempt login
      const res = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/auth/login`,
        {
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(requestBody),
          method: "POST",
        }
      );

      // Error handling
      if (res.status !== 200) {
        throw new Error("Incorrect Username or Password");
      }

      const { authToken } = await res.json();

      // If 'Remember Me' is checked, we save to local storage
      if (rememberMe) {
        localStorage.setItem("authToken", authToken);
        sessionStorage.removeItem("authToken");
      } else {
        localStorage.removeItem("authToken");
        sessionStorage.setItem("authToken", authToken);
      }

      // Reset our form state upon success
      dispatch(resetFormState());
      window.location.replace("/app");
    } catch (e) {
      dispatch(
        updateFormState({
          formKey: "login",
          newState: { submitting: false, error: e.message },
        })
      );
    }
  };

// Login
export const registerUser =
  ({ username, password, email, firstName, lastName, acceptedTerms }) =>
  async (dispatch) => {
    try {
      // Set our login state
      dispatch(
        updateFormState({
          formKey: "register",
          newState: { submitting: true, error: "" },
        })
      );

      // Setup our request
      const requestBody = {
        username,
        password,
        email,
        firstName,
        lastName,
        acceptedTerms,
      };

      // Attempt login
      const res = await fetch(`${process.env.REACT_APP_BASE_API_URL}/users`, {
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(requestBody),
        method: "POST",
      });

      // Error handling
      if (res.status !== 201) {
        throw new Error("Register new user failed");
      }

      const { authToken } = await res.json();

      // We automatically log in the user, but we set to session storage because there is no 'remember me' option to register
      sessionStorage.setItem("authToken", authToken);

      // Reset our form state upon success
      dispatch(resetFormState());
      window.location.replace("/app");
    } catch (e) {
      dispatch(
        updateFormState({
          formKey: "register",
          newState: { submitting: false, error: e.message },
        })
      );
    }
  };

/**
 * Create a new project
 * @param {Object} fields - Field passing to API to create a new project
 * @returns Automatically updates/redirects to our new project if we create one
 */
export const createProject = (fields) => async (dispatch, getState) => {
  try {
    dispatch(
      updateFormState({
        formKey: "createProject",
        newState: { submitting: true, error: "" },
      })
    );

    const state = getState();
    const authToken = getAuthToken();

    // Destructure parameters
    const { displayName, description } = fields;
    const { id: company, projects } = state.app.workspace;

    // Setup our request
    const requestBody = {
      displayName,
      company,
      description,
    };

    // API Call Configuration
    const baseUrl = process.env.REACT_APP_BASE_API_URL;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      timeout: 5000, // 5 second timeout
    };

    // Post new project
    const { status, data } = await axios.post(
      `${baseUrl}/projects`,
      requestBody,
      config
    );

    // Handle errors
    if (status !== 201) {
      throw new Error("Create new project failed");
    }

    //? Should we fetch our workspace here rather than updating projects locally?

    // Add our project to our workspace projects
    dispatch(updateWorkspace({ projects: [...projects, data] }));

    // Reset our form state upon success
    dispatch(resetFormState());

    // Reset our modal state
    dispatch(resetModalState());

    // Update our selected project to the newly created one
    dispatch(setProject(data));
  } catch (e) {
    console.log(e.message);
    dispatch(
      updateFormState({
        formKey: "createProject",
        newState: { submitting: false, error: e.message },
      })
    );
  }
};

/**
 * Single function to be called on page load to pull all user information if it doesn't already exist
 * This function will chain calls in the following order user -> workspace -> project/gdd -> board/ticket/page
 * @param {Object} keys object that contains key:ID pairs for API calls, such as workspace: workspaceId
 * @returns Object containing app information stored in redux
 */
export const getAppInformation =
  (keys = {}) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const { user } = state.app;

      // Destructure our request data (should be a key for each request such as workspace, ticket, etc.)
      const { workspace, project, board, ticket, gddPage } = keys;

      // Check to see if we have a workspace key
      const shouldFetchWorkspace = Object.keys(keys).includes("workspace");

      // Declare response variables (should be a variable for each request such as workspaceResponse, ticketResponse, etc.)
      let workspaceResponse, userResponse, projectResponse;

      // Get our auth token from localstorage/sessionstorage
      const authToken = getAuthToken();

      // API Call Configuration
      const baseUrl = process.env.REACT_APP_BASE_API_URL;
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        timeout: 5000, // 5 second timeout
      };

      // 1. Fetch our user if we don't already have one
      if (!Object.keys(user).length) {
        // We will always request our user first
        const res = await axios.get(`${baseUrl}/users/me`, config);
        userResponse = res.data;

        // Handle /me endpoint error
        if (userResponse.code !== 200) {
          throw new Error("Error fetching user data");
        }
      }

      // 2. Fetch our workspace next
      if (shouldFetchWorkspace) {
        // If our workspace key has an ID, use that to fetch a new workspace, otherwise, default to our userResponse
        const workspaceId = workspace
          ? workspace
          : userResponse.user.defaultWorkspace.urlId;
        const res = await axios.get(
          `${baseUrl}/companies?urlId=${workspaceId}`,
          config
        );
        workspaceResponse = res.data;

        // Handle /companies endpoint error
        if (workspaceResponse.code !== 200) {
          throw new Error("Error fetching workspace");
        }
      }

      // 3. Fetch our project
      if (project && workspaceResponse.workspace) {
        // TODO: Create an endpoint for fetching project directly

        // Since we currently have a workspace response, we will just find our project within it
        projectResponse = workspaceResponse.workspace.projects.find(
          (currentProject) => currentProject.urlId === project
        );
      }

      // Keys that will be set from updated API calls
      const appData = {
        project: projectResponse, // This will break when we get project from an API
        workspace: workspaceResponse.workspace,
        user: userResponse && userResponse.user,
      };

      dispatch(setAppData(appData));

      // 4. Handle our optional dispatches

      // Dispatch /boards route calls
      if (projectResponse && keys.boards) {
        dispatch(getBoards(projectResponse.id));
      }

      // Dispatch /gdd route calls (also include optional pageID)
      if (projectResponse && keys.gdd) {
        dispatch(getGdd(projectResponse.gdd, gddPage));
      }

      // Dispatch /tickets route call
      if (projectResponse && keys.tickets) {
        dispatch(getTickets(projectResponse.id));
      }

      // Dispatch for /board/:boardId route
      if (board && projectResponse) {
        dispatch(getBoardById(projectResponse.id, board));
      }

      if (ticket && projectResponse) {
        dispatch(getTicketByKey(projectResponse.id, ticket));
      }
    } catch (e) {
      const { message } = e;
      if (message.includes("401")) {
        // Clear our authtokens and force user to login
        localStorage.removeItem("authToken");
        sessionStorage.removeItem("authToken");
      }
    }
  };

/**
 * Get boards given a project ID and store them at state.app.boards
 * @param {String} projectId - Project ID to fetch boards from
 * @returns list of boards from a given project
 */
const getBoards = (projectId) => async (dispatch) => {
  try {
    // Get our auth token from localstorage/sessionstorage
    const authToken = getAuthToken();

    // API Call Configuration
    const baseUrl = process.env.REACT_APP_BASE_API_URL;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      timeout: 5000, // 5 second timeout
    };

    const { data } = await axios.get(
      `${baseUrl}/projects/${projectId}/boards?minify=true`,
      config
    );

    // Handle /companies endpoint error
    if (data.code !== 200) {
      throw new Error("Error fetching boards");
    }

    // ! Consider how we set this if we ever fetch boards for another proejct
    dispatch(setBoards(data.boards));
  } catch (e) {
    console.log(e.message);
  }
};

export const getTickets = (projectId) => async (dispatch) => {
  try {
    const authToken = getAuthToken();

    // API Call Configuration
    const baseUrl = process.env.REACT_APP_BASE_API_URL;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      timeout: 5000, // 5 second timeout
    };

    // TODO: Eventually add filter to this
    const { data } = await axios.get(
      `${baseUrl}/projects/${projectId}/tickets`,
      config
    );

    // Handle /companies endpoint error
    if (data.code !== 200) {
      throw new Error("Error fetching tickets");
    }

    dispatch(setTickets(data.tickets));
  } catch (e) {
    console.log(e.message);
  }
};

/**
 * Get boards given a project ID and store them at state.app.boards
 * @param {String} projectId - Project ID to fetch boards from
 * @returns list of boards from a given project
 */
const getBoardById = (projectId, boardId) => async (dispatch) => {
  try {
    // Get our auth token from localstorage/sessionstorage
    const authToken = getAuthToken();

    // API Call Configuration
    const baseUrl = process.env.REACT_APP_BASE_API_URL;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      timeout: 5000, // 5 second timeout
    };

    const { data } = await axios.get(
      `${baseUrl}/projects/${projectId}/boards?urlId=${boardId}`,
      config
    );

    // Handle /companies endpoint error
    if (data.code !== 200) {
      throw new Error("Error fetching boards");
    }

    // ! Consider how we set this if we ever fetch boards for another proejct
    const newBoard = data.boards[0];

    dispatch(setBoard(newBoard));
  } catch (e) {
    console.log(e.message);
  }
};

/**
 * Create a new board
 * @param {Object} fields - Field passing to API to create a new board
 * @returns Automatically updates/redirects to our new project if we create one
 */
export const createBoard = (fields) => async (dispatch, getState) => {
  try {
    dispatch(
      updateFormState({
        formKey: "createBoard",
        newState: { submitting: true, error: "" },
      })
    );

    const state = getState();
    const authToken = getAuthToken();

    // Destructure parameters
    const { displayName } = fields;
    const { project, boards } = state.app;

    console.log(project);

    // Setup our request
    const requestBody = {
      displayName,
      project: project.id,
    };

    // API Call Configuration
    const baseUrl = process.env.REACT_APP_BASE_API_URL;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      timeout: 5000, // 5 second timeout
    };

    // Post new project
    const { status, data } = await axios.post(
      `${baseUrl}/boards`,
      requestBody,
      config
    );

    // Handle errors
    if (status !== 201) {
      throw new Error("Create new board failed");
    }

    // Update our array of boards
    dispatch(setBoards([...boards, data]));

    // Reset our form state upon success
    dispatch(resetFormState());

    // Clear our modal (in case it's open)
    dispatch(resetModalState());
  } catch (e) {
    console.log(e.message);
    dispatch(
      updateFormState({
        formKey: "createBoard",
        newState: { submitting: false, error: e.message },
      })
    );
  }
};

/**
 * Create a new ticket
 * @param {Object} fields - Field passing to API to create a new ticket
 * @returns Automatically updates our ticket list if we create one
 */
export const createTicket = (fields) => async (dispatch, getState) => {
  try {
    dispatch(
      updateFormState({
        formKey: "createTicket",
        newState: { submitting: true, error: "" },
      })
    );

    const state = getState();
    const authToken = getAuthToken();

    // Destructure parameters
    const { type, summary, priority, assignee, taskCategory, description } =
      fields;
    const { project, workspace, tickets } = state.app;

    // Setup our request
    const requestBody = {
      company: workspace.id,
      project: project.id,
      type,
      summary,
      priority,
      description,
      assignee,
      cost: 0,
      taskCategory,
      tags: [],
      status: "READY",
    };

    // API Call Configuration
    const baseUrl = process.env.REACT_APP_BASE_API_URL;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      timeout: 5000, // 5 second timeout
    };

    // Post new project
    const { status, data } = await axios.post(
      `${baseUrl}/projects/${project.id}/tickets/`,
      requestBody,
      config
    );

    // Handle errors
    if (status !== 201) {
      throw new Error("Create new ticket failed");
    }

    // Update our array of boards
    dispatch(setTickets([...tickets, data]));

    // Reset our form state upon success
    dispatch(resetFormState());

    // Clear our modal (in case it's open)
    dispatch(resetModalState());
  } catch (e) {
    console.log(e.message);
    dispatch(
      updateFormState({
        formKey: "createTicket",
        newState: { submitting: false, error: e.message },
      })
    );
  }
};

/**
 * Edit an existing ticket
 * @param {Object} fields - Field passing to API to create a new ticket
 * @returns Automatically updates our ticket list if we create one
 */
export const editTicket =
  (ticketId, fields, key) => async (dispatch, getState) => {
    try {
      dispatch(
        updateFormState({
          formKey: "createTicket",
          newState: { submitting: true, error: "" },
        })
      );

      const state = getState();
      const authToken = getAuthToken();

      // Destructure parameters
      const {
        type,
        summary,
        priority,
        assignee,
        taskCategory,
        description,
        cost,
        parent,
        acceptanceCriteria,
        tags,
      } = fields;
      const { project } = state.app;

      // Setup our request
      const requestBody = {
        id: ticketId,
        type,
        taskCategory,
        cost,
        summary,
        description,
        priority,
        status: fields.status,
        assignee: assignee || null,
        parent: parent || null,
        acceptanceCriteria,
        tags,
        project: project.id,
      };

      // API Call Configuration
      const baseUrl = process.env.REACT_APP_BASE_API_URL;
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        timeout: 5000, // 5 second timeout
      };

      // Post new project
      const { status, data } = await axios.put(
        `${baseUrl}/tickets/${ticketId}/`,
        requestBody,
        config
      );

      // Handle errors
      if (status !== 201) {
        throw new Error("Edit ticket failed");
      }

      // TODO: Eventually remove this API call when we edit a ticket
      dispatch(getTicketByKey(project.id, key));
    } catch (e) {
      console.log(e.message);
    }
  };

/**
 * Get ticket by ID
 * @param {String} projectId - ID of project that we are fetching from
 * @param {String} key - key of ticket that we are looking up
 * @returns
 */
const getTicketByKey = (projectId, key) => async (dispatch) => {
  try {
    // Get our auth token from localstorage/sessionstorage
    const authToken = getAuthToken();

    // API Call Configuration
    const baseUrl = process.env.REACT_APP_BASE_API_URL;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      timeout: 5000, // 5 second timeout
    };

    const { data } = await axios.get(
      `${baseUrl}/projects/${projectId}/tickets/?key=${key}&populate=subtasks`,
      config
    );

    // Handle /companies endpoint error
    if (data.code !== 200) {
      throw new Error("Error fetching ticket");
    }

    const ticket = data.tickets[0];

    if (!ticket) {
      throw new Error("Error fetching Ticket");
    }

    dispatch(setTicket(ticket));
  } catch (e) {
    console.error(e.message);
  }
};

/**
 * Get GDD by ID
 * @param {String} gddId - ID of project that we are fetching from
 * @returns
 */
const getGdd = (gddId, pageId) => async (dispatch) => {
  try {
    // Get our auth token from localstorage/sessionstorage
    const authToken = getAuthToken();

    // API Call Configuration
    const baseUrl = process.env.REACT_APP_BASE_API_URL;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      timeout: 5000, // 5 second timeout
    };

    const { data } = await axios.get(`${baseUrl}/gdd/${gddId}/`, config);

    // Handle /companies endpoint error
    if (data.code !== 200) {
      throw new Error("Error fetching ticket");
    }

    const { gdd } = data;

    // If we have a page ID, fetch it (since we have a gdd.id)
    if (pageId && gdd.id) {
      dispatch(getGddPageById(gdd.id, pageId));
    }

    // At the end set our GDD
    dispatch(setGdd(data.gdd));
  } catch (e) {
    console.log(e.message);
  }
};

/**
 * Create a new page in our GDD
 * @param {Object} fields - Field passing to API to create a new board
 * @returns Automatically updates/redirects to our new project if we create one
 */
export const createGddPage = (fields) => async (dispatch, getState) => {
  try {
    dispatch(
      updateFormState({
        formKey: "createGddPage",
        newState: { submitting: true, error: "" },
      })
    );

    const state = getState();
    const authToken = getAuthToken();

    // Destructure parameters
    const { category, parent, summary, title } = fields;
    const { project, gdd } = state.app;

    // Setup our request
    const requestBody = {
      project: project.id,
      gdd: gdd.id,
      category,
      parent,
      summary,
      title,
    };

    // API Call Configuration
    const baseUrl = process.env.REACT_APP_BASE_API_URL;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      timeout: 5000, // 5 second timeout
    };

    // Post new project
    const { status, data } = await axios.post(
      `${baseUrl}/gdd/${gdd.id}/pages`,
      requestBody,
      config
    );

    // Handle errors
    if (status !== 201) {
      throw new Error("Create new page failed");
    }

    // Update our gdd
    dispatch(setGdd(data.gdd));

    // Reset our form state upon success
    dispatch(resetFormState());

    // Clear our modal (in case it's open)
    dispatch(resetModalState());
  } catch (e) {
    console.log(e.message);
    dispatch(
      updateFormState({
        formKey: "createGddPage",
        newState: { submitting: false, error: e.message },
      })
    );
  }
};

/**
 * Create a new page in our GDD
 * @param {Object} fields - Field passing to API to create a new board
 * @returns Automatically updates/redirects to our new project if we create one
 */
export const reparentGddPage =
  (pageIndex, position, parentIndex, pageId) => async (dispatch, getState) => {
    try {
      const state = getState();
      const authToken = getAuthToken();

      // Destructure parameters
      const { gdd } = state.app;

      // Setup our request
      const requestBody = {
        id: pageId,
        gdd: gdd.id,
        op: "move",
        path: "pages",
        data: {
          pageIndex,
          position,
          parent: parentIndex,
        },
      };

      // API Call Configuration
      const baseUrl = process.env.REACT_APP_BASE_API_URL;
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        timeout: 5000, // 5 second timeout
      };

      // Post new project
      const { status, data } = await axios.patch(
        `${baseUrl}/gdd/${gdd.id}/pages/${pageId}`,
        requestBody,
        config
      );

      // Handle errors
      if (status !== 200) {
        throw new Error("Move gdd page");
      }

      dispatch(setGdd(data.gdd));
    } catch (e) {
      console.log(e.message);
    }
  };

/**
 * Get GDD Page by ID
 * @param {String} pageId - ID of GDD Page that we are fetching
 * @param {String} gddId - ID of GDD that we are requesting the page from
 * @returns
 */
const getGddPageById = (gddId, pageId) => async (dispatch) => {
  try {
    // Get our auth token from localstorage/sessionstorage
    const authToken = getAuthToken();

    // API Call Configuration
    const baseUrl = process.env.REACT_APP_BASE_API_URL;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      timeout: 5000, // 5 second timeout
    };

    const { data } = await axios.get(
      `${baseUrl}/gdd/${gddId}/pages/${pageId}`,
      config
    );

    // Handle /companies endpoint error
    if (data.code !== 200) {
      throw new Error("Error fetching ticket");
    }

    dispatch(setGddPage(data.page));
  } catch (e) {
    console.log(e.message);
  }
};

/**
 * Update the title/summary of the gdd page
 * @param {Object} fields - Field passing to API to create a new board
 * @returns Automatically updates/redirects to our new project if we create one
 */
export const updateGddPage =
  (pageId, title, summary) => async (dispatch, getState) => {
    try {
      const state = getState();
      const authToken = getAuthToken();

      // Destructure parameters
      const { gdd } = state.app;

      const gddPageIndex = gdd.pages.findIndex(
        (page) => page.pageId === pageId
      );

      // Setup our request
      const requestBody = {
        pageId,
        gdd: gdd.id,
        summary,
        title,
        gddPageIndex,
      };

      // API Call Configuration
      const baseUrl = process.env.REACT_APP_BASE_API_URL;
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        timeout: 5000, // 5 second timeout
      };

      // Post new project
      const { status, data } = await axios.put(
        `${baseUrl}/gdd/${gdd.id}/pages/${pageId}`,
        requestBody,
        config
      );

      // Handle errors
      if (status !== 200) {
        throw new Error("Error updating GDD page");
      }

      dispatch(setGdd(data.gdd));
      dispatch(setGddPage(data.page));
    } catch (e) {
      console.log(e.message);
    }
  };

/**
 * Update the title/summary of the gdd page
 * @param {Object} fields - Field passing to API to create a new board
 * @returns Automatically updates/redirects to our new project if we create one
 */
export const addCardToBoard = (ticketId) => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = getAuthToken();

    // Destructure parameters
    const { board } = state.app;

    // Setup our request
    const requestBody = {
      id: board.id,
      cardId: ticketId,
    };

    // API Call Configuration
    const baseUrl = process.env.REACT_APP_BASE_API_URL;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      timeout: 5000, // 5 second timeout
    };

    // Post new project
    const { status, data } = await axios.post(
      `${baseUrl}/boards/${board.id}/cards`,
      requestBody,
      config
    );

    // Handle errors
    if (status !== 201) {
      throw new Error("Error updating GDD page");
    }

    dispatch(setBoard(data.board));
  } catch (e) {
    console.log(e.message);
  }
};

// move card to new lane
export const handleLaneChange =
  (cardId, prevLane, newLane) => async (dispatch, getState) => {
    try {
      const state = getState();
      const authToken = getAuthToken();

      // Destructure parameters
      const { board } = state.app;

      // Setup our request
      const requestBody = {
        id: board.id,
        cardId,
        prevLane,
        newLane,
        modified: board.modified,
      };

      // API Call Configuration
      const baseUrl = process.env.REACT_APP_BASE_API_URL;
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        timeout: 5000, // 5 second timeout
      };

      // Post new project
      const { status, data } = await axios.put(
        `${baseUrl}/boards/${board.id}/cards/${cardId}`,
        requestBody,
        config
      );

      // Handle errors
      if (status !== 200) {
        throw new Error("Error updating GDD page");
      }

      dispatch(setBoard(data.board));
    } catch (e) {
      console.log(e.message);
    }
  };

// Remove card from board
export const handleRemoveCardFromBoard =
(cardId) => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = getAuthToken();

    // Destructure parameters
    const { board } = state.app;

    // API Call Configuration
    const baseUrl = process.env.REACT_APP_BASE_API_URL;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      timeout: 5000, // 5 second timeout
    };

    // Post new project
    const { status, data } = await axios.delete(
      `${baseUrl}/boards/${board.id}/cards/${cardId}`,
      config
    );

    // Handle errors
    if (status !== 201) {
      throw new Error("Error updating GDD page");
    }

    dispatch(setBoard(data.board));
  } catch (e) {
    console.log(e.message);
  }
};

export const resetPassword = (fields) => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = getAuthToken();
    const userId = state.app.user.id;

    dispatch(
      updateFormState({
        formKey: "reset-password",
        newState: { submitting: true, error: "" },
      })
    );

    // Destructure parameters
    const { currentPassword, newPassword } = fields;

    // Setup our request
    const requestBody = {
      userId,
      currentPassword,
      newPassword,
    };

    // API Call Configuration
    const baseUrl = process.env.REACT_APP_BASE_API_URL;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      timeout: 5000, // 5 second timeout
    };

    // Post new project
    const { status, data } = await axios.post(
      `${baseUrl}/users/${userId}/reset_password`,
      requestBody,
      config
    );

    // Handle errors
    if (status !== 204) {
      throw new Error("Reset Password Failed");
    }

    dispatch(
      updateFormState({
        formKey: "reset-password",
        newState: { submitting: false, error: "" },
      })
    );

    // Redirect to /app
    window.location.replace("/app");
  } catch (e) {
    console.log(e.message);
    dispatch(
      updateFormState({
        formKey: "reset-password",
        newState: { submitting: false, error: e.message },
      })
    );
  }
};
