import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Card } from "../../../../../components";
import {
  getTypeIcon,
  getFormattedType,
  getCostType,
  getPriorityLabel,
  getPriorityIcon,
  getStatusColor,
} from "../../../../../utils";

export default function ViewDetailsCard({
  ticket,
  project,
  handleEditDetails,
}) {
  const {
    taskCategory,
    cost,
    priority,
    tags,
    parent,
    status,
    type,
    description,
    summary,
  } = ticket;
  const { costType } = project;

  const cardHeader = () => {
    return (
      <div className="card-header">
        <h2>{summary}</h2>
        <button onClick={handleEditDetails} className="primary">
          Edit
        </button>
      </div>
    );
  };

  // TODO: We should also probably remove the color from the 'Type' text and just leave it white like before

  return (
    <Card header={cardHeader()}>
      <h3>Details</h3>
      <div className="details-container">
        <dl>
          <div className="details-row">
            <div>
              <dt>Type:</dt>
              <dd>
                <i>{getTypeIcon(type, taskCategory)}</i>{" "}
                {getFormattedType(type, taskCategory)}
              </dd>
            </div>
            <div>
              <dt>Status:</dt>
              <dd>
                <span
                  className="tag"
                  style={{
                    backgroundColor: getStatusColor(status),
                    color: "black",
                  }}
                >
                  {status}
                </span>
              </dd>
            </div>
          </div>
          <div className="details-row">
            <div>
              <dt>Estimate:</dt>
              <dd>
                {cost} {getCostType(costType)}
              </dd>
            </div>
            <div>
              <dt>Priority:</dt>
              <dd>
                <i>{getPriorityIcon(priority)}</i> {getPriorityLabel(priority)}
              </dd>
            </div>
          </div>
          <div className="details-row">
            <div>
              <dt>Parent:</dt>
              <dd>{parent || "None"}</dd>
            </div>
          </div>
          <div className="details-row">
            <dt>Tags:</dt>
            <dd>{tags}</dd>
          </div>
        </dl>
      </div>
      <h3>Acceptance Criteria</h3>
      <div className="details-container">
        TODO: Checkboxes to toggle individual A/C items here
      </div>
      <section>
        <h3>Description</h3>
        <div className="details-container">
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {description}
          </ReactMarkdown>
        </div>
      </section>
    </Card>
  );
}
