import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { getAppInformation } from "../../../store/app";

export default function ProjectSettingsView(){

  const {workspaceId, projectId} = useParams();
  const dispatch = useDispatch();
  
  useEffect(()=>{
    dispatch(getAppInformation({workspace: workspaceId, project: projectId}));
  }, [workspaceId, projectId, dispatch]);

  return(
    <div className="project-settings-view">
      <h2>Project Settings Placeholder</h2>

      <p>Modify Cost Type</p>
      <p>User List (username / permissions / last active)</p>
      <p>Edit Users Button</p>
      <p>Add User Button</p>
    </div>
  )
}