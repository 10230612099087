import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { getAppInformation } from "../../../store/app";

export default function ReportsView(){

  const {workspaceId, projectId} = useParams();
  const dispatch = useDispatch();
  
  useEffect(()=>{
    dispatch(getAppInformation({workspace: workspaceId, project: projectId}));
  }, [workspaceId, projectId, dispatch]);

  return(
    <div className="reports-view">
      <h2>Reports View Placeholder</h2>

      <p>dashboard with graphs</p>
      <p>Burdown chart (need to have 'deadline' for boards or milestone)</p>
      <p>Hours worked vs hours outstanding</p>
      <p>Gantt Chart?</p>
    </div>
  )
}