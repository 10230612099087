import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router";
import { Scrollbars } from "react-custom-scrollbars-2";
import {
  BoardListView,
  BoardView,
  DashboardView,
  ProjectSettingsView,
  TicketListView,
  TicketView,
  WorkspaceSettingsView,
  GddView,
  GddPageView,
  ReportsView,
  MilestoneView,
} from "../";
import { getAppInformation } from "../../store/app";
import { Footer } from "../../components";
import Sidebar from "./components/Sidebar/Sidebar.jsx";
import Header from "../../components/Header/Header.tsx";

import "./AppView.scss";
import MilestoneListView from "../app/MilestoneListView/MilestoneListView";
import UserSettingsView from "../UserSettingsView/UserSettingsView";

export default function AppView() {
  //=======================================================
  // Hooks / State
  //=======================================================

  const { workspace } = useSelector((state) => state.app);

  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  //=======================================================
  // Effects
  //=======================================================

  // Handle /app location
  useEffect(() => {
    if (!location || !location.pathname) return;

    // Sanitize our /app/ route
    const regex = /\W+/g;
    const pathText = location.pathname.replace(regex, "").toLowerCase();

    if (pathText === "app") {
      // Handle /app

      // Redirect to dashboard if we have workspace ID
      if (workspace.urlId) {
        history.replace(`/app/${workspace.urlId}`);
      } else {
        // Get user + workspace
        dispatch(getAppInformation({ workspace: null }));
      }
    }
  }, [location, workspace, history, dispatch]);

  //=======================================================
  // Render
  //=======================================================

  return (
    <div className="app-view">
      <Header />
      <main className="main-container">
        <Sidebar />
        <div className="right-container">
          <Scrollbars
            style={{ width: "100%", height: "100%" }}
            className="scrollbar-container"
          >
            <div className="view">
              <Switch>
                <Route
                  exact
                  path={`${match.path}/:workspaceId/project/:projectId/boards`}
                >
                  <BoardListView />
                </Route>

                <Route
                  path={`${match.path}/:workspaceId/project/:projectId/board/:boardId`}
                >
                  <BoardView />
                </Route>

                <Route
                  exact
                  path={`${match.path}/:workspaceId/project/:projectId/gdd`}
                >
                  <GddView />
                </Route>

                <Route
                  path={`${match.path}/:workspaceId/project/:projectId/gdd/page/:pageId`}
                >
                  <GddPageView />
                </Route>

                <Route
                  exact
                  path={`${match.path}/:workspaceId/project/:projectId/settings`}
                >
                  <ProjectSettingsView />
                </Route>

                <Route
                  exact
                  path={`${match.path}/:workspaceId/project/:projectId/tickets`}
                >
                  <TicketListView />
                </Route>

                <Route
                  path={`${match.path}/:workspaceId/project/:projectId/ticket/:ticketId`}
                >
                  <TicketView />
                </Route>

                <Route exact path={`${match.path}/:workspaceId/settings`}>
                  <WorkspaceSettingsView />
                </Route>

                <Route
                  exact
                  path={`${match.path}/:workspaceId/project/:projectId/reports`}
                >
                  <ReportsView />
                </Route>

                <Route
                  exact
                  path={`${match.path}/:workspaceId/project/:projectId/milestone/:milestoneId`}
                >
                  <MilestoneView />
                </Route>

                <Route
                  exact
                  path={`${match.path}/:workspaceId/project/:projectId/milestones`}
                >
                  <MilestoneListView />
                </Route>

                <Route path={`${match.path}/:workspaceId/`}>
                  <DashboardView />
                </Route>

                <Route path={`${match.path}/`}>
                  {/* Messaging to user while we hit all endpoints for logging in */}
                  <p>Logging you back in, give us just a moment!</p>
                </Route>
              </Switch>
            </div>

            <Footer />
          </Scrollbars>
        </div>
      </main>
    </div>
  );
}
