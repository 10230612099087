import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { registerUser } from "../../store/app";
import { getAuthToken } from "../../utils";

export default function RegisterView() {
  //=======================================================
  // State
  //=======================================================

  const dispatch = useDispatch();
  const history = useHistory();
  const authToken = getAuthToken();

  //=======================================================
  // React Form
  //=======================================================
  const { register, handleSubmit } = useForm({
    shouldUseNativeValidation: true,
  });

  // Handle submitted form
  const onSubmit = (data) => {
    dispatch(registerUser(data));
  };

  // Redirect to /app if we have an auth token
  useEffect(() => {
    if (authToken) {
      // We will redirect to /app (which is AppView)
      // AppView will make an API call to /me endpoint to get workspace
      // Using 'replace()' doesn't show /login if users hits back button
      history.replace("/app");
    }
  }, [authToken, history]);

  //=======================================================
  // Render
  //=======================================================

  return (
    <div className="register-view">
      Register Page
      <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="register-username">Username</label>
        <input
          id="register-username"
          type="text"
          {...register("username")}
          required
          autoComplete="username"
        />

        <label htmlFor="register-password">Email</label>
        <input
          id="register-password"
          type="email"
          {...register("email")}
          required
          autoComplete="email"
        />

        <label htmlFor="register-firstName">First Name</label>
        <input
          id="register-firstName"
          type="text"
          {...register("firstName")}
          required
          autoComplete="firstName"
        />

        <label htmlFor="register-lastName">Last Name</label>
        <input
          id="register-lastName"
          type="text"
          {...register("lastName")}
          required
          autoComplete="lastName"
        />

        <label htmlFor="register-password">Password</label>
        <input
          id="register-password"
          type="password"
          {...register("password")}
          required
          autoComplete="password"
        />

        <input
          id="register-acceptedTerms"
          type="checkbox"
          {...register("acceptedTerms")}
        />
        <label htmlFor="register-acceptedTerms">
          Terms and Conditions Agreement
        </label>

        <button className="primary">Register</button>
      </form>
    </div>
  );
}
