import { useSelector, useDispatch } from 'react-redux';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { IconArrowUp, IconPieChart, IconCube, IconClipboard, IconKanban, IconModel, IconBarChart, IconTools, IconSettings } from '../../../../assets/icons';

import './Sidebar.scss';
import { setProject } from '../../../../store/app';
import { NavLink } from 'react-router-dom';


/**
 * Contains all logic pertaining to our sidebar displayed in AppView.jsx
 * @returns Sidebar Component
 */
export default function Sidebar(){

  //=======================================================
  // Hooks / State
  //=======================================================


  const {workspace, project} = useSelector(state => state.app);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch(setProject());
  const history = useHistory();


  //=======================================================
  // Render Components
  //=======================================================

  
  /**
   * Returns memoized list of recently viewed projects within current workspace
   */
  const recentProjects = useMemo(()=>{

    // TODO: Use recent projects list from API

    // Handler if we have no projects
    if(!workspace.projects) return;

    // Handler for click
    const setCurrentProject = e =>{
      e.preventDefault();

      const selectedUrlId = e.target.getAttribute('name');

      // Get our project to set
      const selectedProject = workspace.projects.find(project => project.urlId === selectedUrlId);

      // Set our project
      dispatch(setProject(selectedProject));

      // Close our dropdown
      setDropdownOpen(false);

      // Go ahead and redirect to our dashboard
      // This prevents a user from being stuck on broken pages
      history.push(`/app/${workspace.urlId}/`)
    }

    // Render
    return workspace.projects.map((project, index) =>{
      return (
        <li key={`${project.urlId}_${index}`}>
          <button className="link-button" name={project.urlId} onClick={setCurrentProject} tabIndex={dropdownOpen ? 0 : -1}>
            {project.displayName}
          </button>
        </li>
      )
    })

  }, [workspace, dispatch, history, dropdownOpen]);


  /**
   * Returns height of the project list to allow for transition to work
   */
  const dropdownHeight = useMemo(()=>{
    if(!recentProjects) return 0;

    // Calculate height by number of projects
    const projectCount = recentProjects.length;

    // Project height is 35px, header is 30px
    return 30 + (35 * projectCount);

  }, [recentProjects])


  /**
   * Returns all links that require a selected project
   * @returns List items of all project links
   */
  const getProjectLinks = () =>{
    return (
      <>
        <li>
          <NavLink exact={true} activeClassName="active" to={generateBaseLink() + '/boards'} title="Boards"><i><IconKanban/></i>Boards</NavLink>
        </li>
      
        <li>
          <NavLink exact={true} activeClassName="active" to={generateBaseLink() + '/tickets'} title="Tickets"><i><IconClipboard/></i>Tickets</NavLink>
        </li>

        <li>
          <NavLink exact={true} activeClassName="active" to={generateBaseLink() + '/milestones'} title="Milestones"><i><IconCube/></i>Milestones</NavLink>
        </li>

        <li>
          <NavLink exact={true} activeClassName="active" to={generateBaseLink() + '/gdd'} title="Design Doc"><i><IconModel/></i>Design Doc</NavLink>
        </li>

        <li>
          <NavLink exact={true} activeClassName="active" to={generateBaseLink() + '/reports'} title="Reports"><i><IconBarChart/></i>Reports</NavLink>
        </li>

        {/* Deprecated
         <li>
          <NavLink exact={true} activeClassName="active" to={generateBaseLink() + '/requests'} title="Bug Requests"><i><IconAlert /></i>Bug Requests</NavLink>
        </li> 
        */}

        <li>
          <NavLink exact={true} activeClassName="active" to={generateBaseLink() + '/settings'} title="Project Settings"><i><IconTools/></i>Project Settings</NavLink>
        </li>
      </>
    )
  }
 
  /**
   * Generates a base link for our sidebar links
   * @param {Boolean} dashboardOnly If true, only return dashboard route
   * @returns Base URL determined by workspace + project
   */
  const generateBaseLink = (dashboardOnly = false) =>{
    if(!Object.keys(workspace).length) return '';
    const workspaceRoute = `/app/${workspace.urlId}`;

    // If we have a project, setup base route
    if(Object.keys(project).length && !dashboardOnly){
      return `${workspaceRoute}/project/${project.urlId}`;
    }

    // Default to workspace only
    return workspaceRoute;
  }


  //=======================================================
  // Handlers
  //=======================================================

  // Called when we click the project selection dropdown button
  const handleDropdownClicked = e =>{
    e.preventDefault();
    setDropdownOpen(!dropdownOpen);
  }

  //=======================================================
  // Render
  //=======================================================


  return (
    <div className="sidebar">
      <div className="header">

        {/* Workspace Title */}
        <div className="title">
          {workspace?.displayName ? workspace.displayName : 'Loading Workspace'}
        </div>

        {/* Select Project Dropdown Button */}
        <button className="link-button dropdown-button" onClick={handleDropdownClicked}>
          {project?.displayName ? project.displayName : 'Select Project'}
          <i className={dropdownOpen ? '' : 'flipped'}><IconArrowUp /></i>
        </button>

        {/* Container that holds our recent projects list */}
        <div className="projects-container" style={{height: dropdownOpen ? dropdownHeight : 0}}>
          
          <div className="projects-header">
            <p>Projects</p>
            <button className="link-button" tabIndex={dropdownOpen ? 0 : -1}>View All</button>
          </div>

          <div className="projects-list">
            <ul>
              {recentProjects}
            </ul>
          </div>

        </div>
      </div>

      {/* App links */}
      <nav className="navigation">
       <ul>
          
          {/* Dashboard Link */}
          <li>
            <NavLink exact={true} activeClassName="active" to={generateBaseLink(true)} title="Dashboard"><i><IconPieChart/></i>Dashboard</NavLink>
          </li>

          {/* These links are only displayed if we have a currently selected project */}
          {Object.keys(project).length > 0 &&
            getProjectLinks()            
          }
          
          {/* Workspace Settings Link*/}
          <li>
            <NavLink exact={true} activeClassName="active" to={generateBaseLink(true) + '/settings'} title="Dashboard"><i><IconSettings/></i>Workspace Settings</NavLink>
          </li>



       </ul>
      </nav>
    </div>
  )
}