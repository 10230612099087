import Header from "../../components/Header/Header";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./UserSettingsView.scss";
import { useDispatch, useSelector } from "react-redux";
import { getUser, resetPassword } from "../../store/app";
import { SubmitHandler, useForm } from "react-hook-form";
import { RootState } from "../../store/store";
import { useEffect } from "react";

type FormValues = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export default function UserSettingsView() {
  const dispatch = useDispatch();
  const { formState, user } = useSelector((state: RootState) => state.app);
  const { register, handleSubmit } = useForm<FormValues>({
    shouldUseNativeValidation: true,
  });

  const handleFormUpdate: SubmitHandler<FormValues> = (fields) => {
    dispatch(resetPassword(fields));
  };

  useEffect(() => {
    if (!Object.keys(user).length) {
      dispatch(getUser());
    }
  }, [user]);

  return (
    <div className="user-settings-view">
      <Header />
      <div className="main-container">
        <h2>User Settings</h2>

        <Card>
          <Card.Header>Test</Card.Header>
          <Card.Body>
            <Form onSubmit={handleSubmit(handleFormUpdate)}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Current Password</Form.Label>
                <Form.Control
                  disabled={formState["reset-password"].submitting}
                  type="password"
                  placeholder="Current Password"
                  {...register("currentPassword")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  disabled={formState["reset-password"].submitting}
                  type="password"
                  placeholder="New Password"
                  {...register("newPassword")}
                />
              </Form.Group>

              <Button
                variant="primary"
                type="submit"
                disabled={formState["reset-password"].submitting}
              >
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
