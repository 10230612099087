import {
  IconArt,
  IconBug,
  IconDesign,
  IconEpic,
  IconFeature,
  IconMarketing,
  IconProgramming,
  IconSound,
  IconSpike,
  IconStory,
  IconTask,
  IconWriting,
  IconLow,
  IconMedium,
  IconHigh,
  IconCritical,
  IconUser,
  IconThreeDots,
  IconMenu,
} from "../assets/icons";

// Helper function for returning the auth token
export const getAuthToken = () => {
  // Try reading from local storage first before reading session-storage
  return localStorage.getItem("authToken")
    ? localStorage.getItem("authToken")
    : sessionStorage.getItem("authToken");
};

//=======================================================
// Text Utils
//=======================================================

// Returns the color for a given status on the ticket page
// ! These are also currently contained on the server at the moment. We should sync them in the future.
export const getStatusColor = (status) => {
  switch (status) {
    case "READY":
      return "#1191ec";
    case "PROGRESS":
      return "#ae40f2";
    case "TESTING":
      return "#f24082";
    case "BLOCKED":
      return "#dff240";
    case "COMPLETED":
    case "CLOSED":
      return "#11ece5";
    default:
      return "#00000000";
  }
};

//Returns a cost type label
export const getCostType = (costType) => {
  switch (costType) {
    case "FIB":
      return "Points";
    case "HOURS":
      return "Hours";
    case "EXP":
      return "Points";
    case "DAYS":
      return "Days";
    default:
      return "Cost";
  }
};

export const getPriorityLabel = (priority) => {
  switch (priority) {
    case 1:
      return "Low";
    case 2:
      return "Medium";
    case 3:
      return "High";
    case 4:
      return "Critical";
    default:
      return "Invalid Priority";
  }
};

//=======================================================
// Dropdown Utils
//=======================================================

// Returns list of priority options
export const getPriorityOptions = () => {
  return [
    { value: 1, label: "Low" },
    { value: 2, label: "Medium" },
    { value: 3, label: "High" },
    { value: 4, label: "Critical" },
  ];
};

// Returns ticket type options
export const getStatusOptions = () => {
  return [
    { value: "READY", label: "Ready" },
    { value: "PROGRESS", label: "Progress" },
    { value: "TESTING", label: "Testing" },
    { value: "BLOCKED", label: "Blocked" },
    { value: "COMPLETED", label: "Completed" },
    { value: "CLOSED", label: "Closed" },
  ];
};

// Returns ticket type options
export const getTypeOptions = () => {
  return [
    { value: "BUG", label: "Bug" },
    { value: "FEATURE", label: "Feature" },
    { value: "SPIKE", label: "Spike" },
    { value: "EPIC", label: "Epic" },
    { value: "STORY", label: "Story" },
    { value: "TASK", label: "Task" },
  ];
};

// Returns task category options
export const getTaskCategoryOptions = () => {
  return [
    { value: "PROGRAMMING", label: "Programming" },
    { value: "ART", label: "Art" },
    { value: "DESIGN", label: "Design" },
    { value: "GENERAL", label: "General" },
    { value: "MARKETING", label: "Marketing" },
    { value: "WRITING", label: "Writing" },
    { value: "SOUND", label: "Sound" },
  ];
};

//=======================================================
// Icon Utils
//=======================================================

// Map our svg to priority
export const getPriorityIcon = (priority) => {
  switch (priority) {
    case 1:
      return <IconLow title="Low" style={{ color: "var(--color-low)" }} />;
    case 2:
      return (
        <IconMedium title="Medium" style={{ color: "var(--color-medium)" }} />
      );
    case 3:
      return <IconHigh title="High" style={{ color: "var(--color-high)" }} />;
    case 4:
      return (
        <IconCritical
          title="Critical"
          style={{ color: "var(--color-critical)" }}
        />
      );
    default:
      return "Invalid Priority";
  }
};

/**
 * Get icon by icon name
 * @param {string} iconName Name of the icon to return
 * @returns Icon given an iconName
 */
export const getIcon = (iconName) => {
  switch (iconName) {
    case "user":
      return <IconUser />;
    case "dots":
      return <IconMenu/>;
    default:
      return <></>;
  }
};

//Returns SVG icon for a given ticket type
export const getTypeIcon = (type, category) => {
  //Called if we have a TASK
  const getTaskIcon = (taskCategory) => {
    switch (taskCategory) {
      case "PROGRAMMING":
        return (
          <IconProgramming
            title="Programming"
            style={{ color: "var(--color-task)" }}
          />
        );
      case "ART":
        return (
          <IconArt title="Artwork" style={{ color: "var(--color-task)" }} />
        );
      case "WRITING":
        return (
          <IconWriting title="Writing" style={{ color: "var(--color-task)" }} />
        );
      case "MARKETING":
        return (
          <IconMarketing
            title="Marketing"
            style={{ color: "var(--color-task)" }}
          />
        );
      case "SOUND":
        return (
          <IconSound title="Sounds" style={{ color: "var(--color-task)" }} />
        );
      case "DESIGN":
        return (
          <IconDesign
            title="Designing"
            style={{ color: "var(--color-task)" }}
          />
        );
      case "GENERAL":
        return <IconTask title="Task" style={{ color: "var(--color-task)" }} />;
      default:
        return "Invalid Type";
    }
  };

  // Switch to return icon based on a given type
  switch (type) {
    case "EPIC":
      return <IconEpic title="Epic" style={{ color: "var(--color-epic)" }} />;
    case "TASK":
      return getTaskIcon(category);
    case "BUG":
      return <IconBug title="Bug" style={{ color: "var(--color-bug)" }} />;
    case "FEATURE":
      return (
        <IconFeature
          title="Feature"
          style={{ color: "var(--color-feature)" }}
        />
      );
    case "SPIKE":
      return (
        <IconSpike title="Spike" style={{ color: "var(--color-spike)" }} />
      );
    case "STORY":
      return (
        <IconStory title="Story" style={{ color: "var(--color-story)" }} />
      );
    default:
      return "Invalid Type";
  }
};

//Returns SVG icon for a given ticket type
export const getFormattedType = (type, category) => {
  //Called if we have a TASK
  const getTaskText = (taskCategory) => {
    switch (taskCategory) {
      case "PROGRAMMING":
        return <span>Programming Task</span>;
      case "ART":
        return <span>Art Task</span>;
      case "WRITING":
        return <span>Writing Task</span>;
      case "MARKETING":
        return <span>Marketing Task</span>;
      case "SOUND":
        return <span>Sound Task</span>;
      case "DESIGN":
        return <span>Design Task</span>;
      case "GENERAL":
        return <span>General Task</span>;
      default:
        return "Invalid Type";
    }
  };

  // Switch to return icon based on a given type
  switch (type) {
    case "EPIC":
      return <span>Epic</span>;
    case "TASK":
      return getTaskText(category);
    case "BUG":
      return <span>Bug</span>;
    case "FEATURE":
      return <span>Feature</span>;
    case "SPIKE":
      return <span>Spike</span>;
    case "STORY":
      return <span>Story</span>;
    default:
      return "Invalid Type";
  }
};
