import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getAppInformation,
  getTickets,
  addCardToBoard,
  handleLaneChange,
  setBoard,
} from "../../../store/app";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { Lane } from "./components";

import "./BoardView.scss";

export default function BoardView() {
  //=======================================================
  // State / Hooks
  //=======================================================

  const dispatch = useDispatch();
  const { workspaceId, projectId, boardId } = useParams();
  const { board, project, tickets } = useSelector((state) => state.app);
  const [addCard, setAddCard] = useState(false);

  //Memo of our board
  const boardData = useMemo(() => {
    if (!board || !board.lanes) return { lanes: [] };

    // Loop through our lanes
    const lanes = board.lanes.map((lane) => {
      const style = {
        border: `1px solid ${lane.color}`,
        color: lane.color,
      };

      let cards = [];
      // Loop through the cards in our lane
      if (lane.cards.length) {
        cards = lane.cards.map((card) => {
          return {
            ...card,
            workspaceId,
            projectId,
          };
        });
      }

      return {
        ...lane,
        style,
        cards,
      };
    });

    // TODO: Put in board data
    return { lanes };
  }, [board, projectId, workspaceId]);

  const generateLayout = () => {
    // Callback when dropped
    const handleDropped = (id, { addedIndex, removedIndex, payload }) => {
      if (addedIndex === null) return;

      // ID is the ID of the lane that we are moving into
      const newLane = id;
      const prevLane = payload.fromLaneId;
      const cardId = payload.id;

      // Dispatch our lane change here
      dispatch(handleLaneChange(cardId, prevLane, newLane));
    };

    return boardData.lanes.map((lane, index) => {
      return (
        <Lane
          data={lane}
          key={`${lane.id}_${index}`}
          handleDropped={handleDropped}
        />
      );
    });
  };

  const toggleAddCard = (e) => {
    e.preventDefault();
    dispatch(getTickets(project.id));
    setAddCard(!addCard);
  };

  const handleAddToBoard = (e) => {
    e.preventDefault();
    const ticketId = e.target.getAttribute("name");

    dispatch(addCardToBoard(ticketId));
  };

  const getTicketList = () => {
    const filtered = tickets.filter((ticket) => !ticket.board);

    return filtered.map((ticket, index) => {
      return (
        <button
          key={`${ticket.urlId}`}
          onClick={handleAddToBoard}
          name={ticket.id}
          className="primary"
        >
          {ticket.summary}
        </button>
      );
    });
  };

  //=======================================================
  // Handlers
  //=======================================================

  // const handleLaneChange = (fromLaneId, toLaneId, cardId, index) =>{
  //   console.log('card moving info', fromLaneId, toLaneId, cardId);
  // }

  //=======================================================
  // Effects
  //=======================================================

  // Fetch our workspace when we load settings
  useEffect(() => {
    dispatch(
      getAppInformation({
        workspace: workspaceId,
        project: projectId,
        board: boardId,
      })
    );

    return () => {
      // Clear our board
      dispatch(setBoard({}));
    };
  }, [workspaceId, projectId, boardId, dispatch]);

  //=======================================================
  // Render
  //=======================================================

  return (
    <div className="board-view">
      <div className="heading">
        <h2>{board.displayName}</h2>
        <div>
          <button className="link-button" onClick={toggleAddCard}>
            Add Card
          </button>
          {addCard && (
            <div className="dropdown">
              <input type="text" placeholder="filter" />
              {/* TODO: Add all tickets here in this dropdown */}
              {getTicketList()}
            </div>
          )}
        </div>
      </div>

      <div className="board">{generateLayout()}</div>
    </div>
  );
}
