import "./WorkspaceSettingsView.scss";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAppInformation, setModalState } from "../../../store/app";
import { useParams } from "react-router";
import { Card } from "../../../components";
import { useSelector } from "react-redux";

export default function WorkspaceSettingsView() {
  //=======================================================
  // Hooks / State
  //=======================================================

  const dispatch = useDispatch();
  const { workspaceId } = useParams();
  const { workspace } = useSelector((state) => state.app);

  //=======================================================
  // Helpers
  //=======================================================

  // Returns our project header
  const getProjectHeader = () => {
    return (
      <div className="button-header">
        <h2>Projects</h2>
        <button onClick={addProjectClicked} className="primary">
          + New Project
        </button>
      </div>
    );
  };

  // Returns our rows of projects
  const getProjectRows = () => {
    return workspace.projects.map((project) => {
      return (
        <tr key={project.urlId}>
          <td>{project.displayName}</td>
          {/* TODO: Fix this return on the API to give us project.users upon createprojectsuccess */}
          <td>{project.users ? project.users.length : 1}</td>
          <td>{/* Placeholder for Activity */}</td>
          <td className="button-cell">
            <button className="link-button">Options Icon</button>
          </td>
        </tr>
      );
    });
  };

  // Returns our users header
  const getUsersHeader = () => {
    return (
      <div className="button-header">
        <h2>Users</h2>
        <button className="primary">+ New User</button>
      </div>
    );
  };

  // Returns our user rows
  const getUserRows = () => {
    return workspace.users.map((u, index) => {
      return (
        <tr key={`${u.user.username}_${index}`}>
          <td>{u.user.username}</td>
          <td>{u.user.email}</td>
          <td>{u.status}</td>
          <td className="button-cell">
            <button className="link-button">Options Icon</button>
          </td>
        </tr>
      );
    });
  };

  //=======================================================
  // Handlers
  //=======================================================

  const addProjectClicked = (e) => {
    e.preventDefault();
    dispatch(setModalState({ visible: true, type: "CREATE_PROJECT" }));
  };

  //=======================================================
  // Effects
  //=======================================================

  // Fetch our workspace when we load settings
  useEffect(() => {
    dispatch(getAppInformation({ workspace: workspaceId }));
  }, [workspaceId, dispatch]);

  //=======================================================
  // Render
  //=======================================================

  if (!Object.keys(workspace).length) return "loading...";

  return (
    <div className="workspace-settings-view">
      <Card header={<h2>Account and Billing</h2>}>
        <dl>
          <dt>Billing Tier:</dt>
          <dd>{workspace.priceTier}</dd>

          <dt>User Seats:</dt>
          <dd>
            {workspace.users.length}/{workspace.seats}
          </dd>

          <dt>Monthly Cost</dt>
          {/* TODO: Add monthly cost */}
          <dd>$0.00</dd>
        </dl>
      </Card>
      <Card header={getProjectHeader()}>
        <table className="link-table">
          <tbody>
            <tr>
              <th>Project Name</th>
              <th>User Count</th>
              <th>Last Activity</th>
            </tr>
            {getProjectRows()}
          </tbody>
        </table>
      </Card>

      <Card header={getUsersHeader()}>
        <table className="link-table">
          <tbody>
            <tr>
              <th>username</th>
              <th>email</th>
              <th>
                Status{" "}
                <div
                  title="If a user's status is 'Active', their last activity timestamp is shown."
                  className="question-mark"
                >
                  (?)
                </div>
              </th>
              <th></th>
            </tr>
            {getUserRows()}
          </tbody>
        </table>
      </Card>
    </div>
  );
}
