import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { login } from "../../store/app";
import { Card, Footer } from "../../components";
import "./LoginView.scss";
import { getAuthToken } from "../../utils";

// Login Page (Redirected from landing currently)
export default function LoginView() {
  //=======================================================
  // State
  //=======================================================

  const authToken = getAuthToken();

  const dispatch = useDispatch();
  const history = useHistory();

  //=======================================================
  // React Form
  //=======================================================
  const { register, handleSubmit } = useForm({
    shouldUseNativeValidation: true,
  });

  // Handle submitted form
  const onSubmit = (data) => {
    dispatch(login(data));
  };

  //=======================================================
  // Effects
  //=======================================================

  // Redirect to /app if we have an auth token
  useEffect(() => {
    if (authToken) {
      // We will redirect to /app (which is AppView)
      // AppView will make an API call to /me endpoint to get workspace
      // Using 'replace()' doesn't show /login if users hits back button
      history.replace("/app");
    }
  }, [authToken, history]);

  //=======================================================
  // Render
  //=======================================================

  return (
    <div className="login-view">
      <Card className="description" header={<h2>Login</h2>}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="login-username">Username</label>
          <input
            id="login-username"
            type="text"
            {...register("username")}
            required
            autoComplete="username"
          />

          <label htmlFor="login-password">Password</label>
          <input
            id="login-password"
            type="password"
            {...register("password")}
            required
            autoComplete="password"
          />

          <div className="remember-container">
            <input
              id="login-remember"
              type="checkbox"
              {...register("rememberMe")}
            />
            <label htmlFor="login-remember">Remember Me</label>
          </div>

          <button className="primary">Login</button>
        </form>
      </Card>
      <Footer />
    </div>
  );
}
