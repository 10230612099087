import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import app from "./app";

const reducer = combineReducers({
  app,
});

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV === "development",
});

export type RootState = ReturnType<typeof reducer>;
export default store;
